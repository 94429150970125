import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Grid, Typography, Divider } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import PersonIcon from '@mui/icons-material/Person';

const BankInfoConfirmationAlert = () => {
  const [unconfirmedBankDetails, setUnconfirmedBankDetails] = useState({});
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchUnconfirmedBankDetails = async () => {
      const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';
      try {
        const response = await fetch(`${apiUrl}/g_unconfBA_details/`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setUnconfirmedBankDetails(data);
        } else {
          // Handle error, could be authentication issue or endpoint not found
          console.error('Failed to fetch unconfirmed bank details');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchUnconfirmedBankDetails();
  }, []);

  const GridRow = ({ label, value }) => (
    <Grid container wrap="nowrap" spacing={1} alignItems="center">
      <Grid item>
        <Typography variant="body2" color="textSecondary" sx={{ width: 24, textAlign: 'center' }}>&bull;</Typography>
      </Grid>
      <Grid item xs zeroMinWidth>
        <Typography variant="body2" color="textSecondary" noWrap>{label}</Typography>
        <Typography variant="body1" noWrap>{value}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <div>
      {Object.keys(unconfirmedBankDetails).length > 0 && (
        <Alert
          severity="info"
          icon={<HourglassEmptyIcon fontSize="inherit" />}
          sx={{ mb: 2 }}
        >
          <AlertTitle>Banking Information Received</AlertTitle>
          <Typography sx={{ mt: 1, mb: 2 }}>
            We have received your banking details and are currently awaiting process confirmation and validation. Please be patient as this may take some time.
          </Typography>
          <Grid container direction="column" spacing={1}>
            <GridRow
              label="Bank Name"
              value={unconfirmedBankDetails.UNCONFIRMED_bank_name || 'N/A'}
            />
            <GridRow
              label="IBAN"
              value={unconfirmedBankDetails.UNCONFIRMED_iban || 'N/A'}
            />
            <GridRow
              label="SWIFT/BIC"
              value={unconfirmedBankDetails.UNCONFIRMED_swift_code || 'N/A'}
            />
            <GridRow
              label="Account Holder's Name"
              value={unconfirmedBankDetails.UNCONFIRMED_account_holder_name || 'N/A'}
            />
          </Grid>
        </Alert>
      )}
                  <Divider sx={{ my: 3 }} />

    </div>
  );
};

export default BankInfoConfirmationAlert;