import React from 'react';
import StrategyImg from '../../../assets/firms2.jpg'; // Placeholder image path, adjust as needed
import './Plan.css'; // Ensure you create and link this CSS file
import GoBackButton from '../../../utils/Goback';
const StrategyPlanComponent = () => {
    return (
        <div className="strategy-container">
                        <GoBackButton/>

            <div className="strategy-section" style={{ backgroundImage: `url(${StrategyImg})` }}></div>
            <div className="strategy-content">
                <h1>Our Strategy and Business Plan</h1>
                <div className="title-bar"></div>
                <p>
                    Our strategy is predicated on a steadfast commitment to upholding the integrity of financial markets and protecting consumers from fraudulent practices. Through comprehensive oversight and rigorous enforcement, we aim to deter misconduct and ensure a fair, transparent, and secure market environment.
                </p>
                <hr />
                <h2>Operational Objectives</h2>
                <p>
                    To effectively combat fraud and protect consumers, our operational objectives include:
                </p>
                <ul>
                    <li>Enhanced monitoring of financial activities to preemptively identify and address potential fraud.</li>
                    <li>Rigorous vetting processes for financial institutions, ensuring compliance with stringent ethical standards.</li>
                    <li>Empowering consumers through education on their rights and the mechanisms of financial protection available to them.</li>
                </ul>
                <hr />
                <h2>Engagement and Collaboration</h2>
                <p>
                    Recognizing the importance of collaboration in achieving our objectives, we actively engage with various stakeholders, including regulatory bodies, consumer advocacy groups, and the financial industry, to foster a united front against fraud.
                </p>
                <hr />
                <h2>Future Directions</h2>
                <p>
                    Looking forward, we are committed to continuous improvement and adaptation of our strategies to meet the evolving challenges of financial fraud, ensuring the security and confidence of consumers in the financial market.
                </p>
            </div>
        </div>
    );
}
 
export default StrategyPlanComponent;
