import React, { useState, useEffect, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Paper,
  List,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import Divider from '@mui/material/Divider';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailIcon from '@mui/icons-material/Mail';
import InboxIcon from '@mui/icons-material/Inbox';
import axios from 'axios';
const InboxMainComp = ({setUnreadCount }) => {
  const [messages, setMessages] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : null;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchInboxMessages = async () => {
      try {
        const response = await axios.get(`${apiUrl}/inbox/messages/`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const messagesData = response.data;
        setMessages(messagesData);
        const unreadCount = messagesData.filter(msg => !msg.read).length;
        setUnreadCount(unreadCount);        
      } catch (error) {
        console.error('There was an error fetching the inbox messages:', error);
      }
    };

    fetchInboxMessages();
  }, [authToken], setUnreadCount);

  const handleAccordionChange = async (messageId) => {
    setExpanded(expanded === messageId ? false : messageId);
    const message = messages.find(msg => msg.id === messageId);
    if (message && !message.read) {
      try {
        await axios.patch(`${apiUrl}/inbox/messages/read/${messageId}/`, {}, {
            headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
  
        setMessages(messages.map(msg => {
          if (msg.id === messageId) {
            return { ...msg, read: true };
          }
          return msg;
        }));
      } catch (error) {
        console.error('Error marking message as read:', error);
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
      <Paper elevation={3} sx={{ my: 2, p: 2 }}>
        <Typography variant="h5" gutterBottom sx={{ pl: 2, color: 'primary.main' }}>
          <InboxIcon sx={{ position: 'relative', top: '7px', mr: 1 }} />
          Inbox
        </Typography>
        <Divider  />

        <List>
          {messages.length === 0 ? (
            <Typography variant="subtitle1" sx={{ mx: 2 }}>
              There are currently no messages.
            </Typography>
          ) : (
            messages.map((message) => (
              <Accordion
                key={message.id}
                expanded={expanded === message.id}
                onChange={() => handleAccordionChange(message.id)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${message.id}-content`}
                  id={`panel${message.id}-header`}
                >
                  <ListItemIcon>
                    <MailIcon sx={{ color: message.read ? 'grey' : '#007bff' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={message.title}
                    secondary={new Date(message.time).toLocaleString()}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Typography variant="body1">
                    {message.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </List>
      </Paper>
    </Box>
  );
};

export default InboxMainComp;
