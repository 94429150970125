// MainLayout.js

import React from 'react';
import Navbar from '../Nav/Navbar';
import FooterComp from '../Footer/Footer';
export function MainLayout({ children }) {
    return (
        <>
            <Navbar />
            <div>{children}</div>
            <FooterComp />
        </>
    );
}
