import React, { useState, useEffect } from 'react';
import AwaitingConfirmComp from './AwaitConfirmDash/AwaitConfirm';
import ConfirmedDashComp from './ConfirmedDash/ConfirmedDash';
import IDPhotoComp from './IDPhoto/IDPhoto';
import BankDetailsComp from './BankDets/BankDets';
import BankInfoConfirmationAlert from './BankDets/AwautBDets/AwaitBDets';
import UserAccounts from '../DITAccs/DitAccs';
import AccountRecoveryConfirmComp from './ShowDit/ShowDit';
import BankStatementComp from './BaStatement/BaStatement';
import ConfirmedBankDets from './ConfirmedBankDets/ConfirmedBankDets';
import { useAuth } from '../../Context/AuthContext';
const MainDashComp = () => {


  const { authTokens, fetchStatus } = useAuth(); // Assuming `fetchStatus` will be called with `authTokens` if needed
  const [status, setStatus] = useState(localStorage.getItem('status') || 'NEW');
  useEffect(() => {
    // This function is declared to be called inside useEffect
    const checkStatus = async () => {
      // Check if we have valid auth tokens before fetching status
      if (authTokens) {
        try {
          const newStatus = await fetchStatus(authTokens);
          if (newStatus) {
            setStatus(newStatus);
            // Update localStorage only if needed
            localStorage.setItem('status', newStatus);
          }
        } catch (error) {
          console.error('Failed to fetch status:', error);
          // Handle additional logic for failed status fetch, like redirecting to login
        }
      }
    };

    checkStatus();
  }, [authTokens, fetchStatus, setStatus]);

  // Now, render different components based on the status
  let content;
  if (status === 'NEW') {
    content = <AwaitingConfirmComp />; 
  } else if (status === 'PENDING') {
    content = <AwaitingConfirmComp />; 
  } else if (status === 'WAITBA') {
    content = <BankDetailsComp /> //
  } else if (status === 'DITDETS') {
    content = <AccountRecoveryConfirmComp /> 
  } else if (status === 'BADETS') {
    content = <BankStatementComp /> 
  } else if (status === 'CONFIRMED') {
    content = <ConfirmedBankDets /> 
    
  } else if (status === 'AWAITBADETS') {
    content = <BankInfoConfirmationAlert /> 
  } else if (status === 'WAITID') {
    content =  <IDPhotoComp />; //
  } else {
    content = <div>Other status: {status}</div>; 
  }

  return (
    <div>
      {content}
      <div>

<UserAccounts/>
    </div>
    </div>
  );
};

export default MainDashComp;
