import React from 'react';
import './TransferOptions.css';

// Import the icons from MUI Icons
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';

const TransferOptionsComp = ({ pendingAmount }) => {
  // You can directly place the icon components in the JSX below
  const totalPending = parseFloat(localStorage.getItem('total_pending')) || 0;
  const totalGenerated = parseFloat(localStorage.getItem('total_generated')) || 0;

  // Format the number with commas
  const formattedTotalPending = totalPending.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });
  const formattedTotalGenerated = totalGenerated.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  });

  // Determine the color based on the amount
  const amountColor = totalPending > 0 ? 'green' : '#007bff'; // Use 'inherit' or any ot
  const amountColor2 = totalGenerated > 0 ? 'green' : '#007bff'; // Use 'inherit' or any ot

  return (
    <div className="transfer-options">
      <div className="transfer-option-card">
        <AddToDriveIcon className="transfer-option-icon" />
        <div className="transfer-option-title">Generated</div>
        <div className="transfer-option-amount"  style={{ color: amountColor2 }}>€{formattedTotalGenerated}</div>
      </div>
      <div className="transfer-option-card">
        <PendingActionsIcon className="transfer-option-icon" />
        <div className="transfer-option-title">Pending Transfer</div>
        <div className="transfer-option-amount" style={{ color: amountColor }}>
                    €{formattedTotalPending}
                </div>
      </div>
      <div className="transfer-option-card">
        <AccountBalanceIcon className="transfer-option-icon" />
        <div className="transfer-option-title">Transfer within same bank</div>
        <div className="transfer-option-amount">€0.00</div>
      </div>
      <div className="transfer-option-card">
        <ImportExportIcon className="transfer-option-icon" />
        <div className="transfer-option-title">Transfer to other bank</div>
        <div className="transfer-option-amount">€0.00</div>
      </div>
    </div>
  );
};

export default TransferOptionsComp;
