import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemText, ListItemIcon, Grid, Paper, CircularProgress } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PaymentIcon from '@mui/icons-material/Payment';
import InfoIcon from '@mui/icons-material/Info';

const formatCurrency = (amount, currency) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(amount);
};

const Step1TransModal = ({ transaction, bankingDetails, nextStep }) => {
  const [loading, setLoading] = useState(true); // State for controlling loading

  // Simulate loading for 2 seconds when the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <Box>
      {loading ? ( // Render loader if loading is true
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box>
          <Typography variant="h5" gutterBottom component="div">
            Transaction Review
          </Typography>
          <Grid container spacing={2}>
            {/* Transaction Details */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Transaction Details
              </Typography>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <PaymentIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Transaction Reference" secondary={transaction.transaction_reference} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <AccountBalanceWalletIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Amount" secondary={formatCurrency(transaction.amount, transaction.currency)} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Sender Name" secondary={transaction.sender_name} />
                </ListItem>
              </List>
            </Grid>

            {/* Banking Details */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>
                Destination Banking Details
              </Typography>
              <List disablePadding>
                <ListItem>
                  <ListItemIcon>
                    <PaymentIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Bank Name" secondary={bankingDetails.bank_name} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <AccountBalanceWalletIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="IBAN" secondary={bankingDetails.iban} />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <InfoIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="SWIFT Code" secondary={bankingDetails.swift_code} />
                </ListItem>
              </List>
            </Grid>
          </Grid>

          {/* Fine Print */}
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            *Please review the details above carefully before proceeding to ensure the accuracy of the transaction.*
          </Typography>

          {/* Proceed Button */}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={nextStep} sx={{ px: 3 }}>
              Proceed to Next Step
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Step1TransModal;
