import React, { useState, useEffect } from 'react';
import background from '../assets/news.jpg';
import './News.css';
import Pagination from '@mui/material/Pagination';
import newsData from './NewsData.json';
import { Link } from 'react-router-dom';

const NewsComp = () => {
    const [page, setPage] = useState(1);
    const storiesPerPage = 3;
    const count = Math.ceil(newsData.length / storiesPerPage);
    const [paginatedNewsStories, setPaginatedNewsStories] = useState([]);
    

    useEffect(() => {
        const startIndex = (page - 1) * storiesPerPage;
        const endIndex = page * storiesPerPage;
        setPaginatedNewsStories(newsData.slice(startIndex, endIndex));
    }, [page]);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <div className="news-container">
            <div className="background-section" style={{ backgroundImage: `url(${background})` }}></div>
            <div className="news-content">
                <h1>Latest News</h1>
                <div className="title-bar"></div>

                <div className="news-articles">
                    {paginatedNewsStories.map((story, index) => (
                        <div key={index} className="news-article">
                            <div className="news-category">
                                <span className="news-category-indicator"></span>
                                {story.category}
                            </div>
                            <Link to={`/news/${story.id}`} className="news-title-link">
                                <h2 className="news-title">{story.title}</h2>
                            </Link>
                            <div className="news-date">{story.date}</div>
                            {/* Add a button or link to display the full story */}
                        </div>
                    ))}
                </div>
                <Pagination count={count} page={page} onChange={handleChange} />
            </div>
        </div>
    );
}

export default NewsComp;
