import React, { useState, useEffect } from 'react';
import './Home.css';
import background from '../assets/back.jpg'; // Ensure the path is correct
import AboutComp from './HomeDetails/about/About';
import ValuesComp from './HomeDetails/values/Values';
import PurposeComp from './HomeDetails/purpose/Purpose';
import { Link } from 'react-router-dom';
import down from '../assets/arrow.png'
import grow from '../assets/plant.png'
import newsData from '../Newsroom/NewsData.json';

// Import other components as needed

const HomeComp = () => {
    const [activeLink, setActiveLink] = useState('about-sab'); // Set 'about-sab' as default
    const [mobileNav, setMobileNav] = useState(true);
    const [recentNews, setRecentNews] = useState([]);
    const [isNewsExpanded, setIsNewsExpanded] = useState(false); // New state for expand/collapse
   
    const toggleNewsExpand = () => setIsNewsExpanded(!isNewsExpanded); // Method to toggle news expansion

    useEffect(() => {
        // Assuming you want to display the first 5 news stories as recent news
        setRecentNews(newsData.slice(0, 5));
    }, []);

    const handleSetActiveLink = (linkName) => {
        setActiveLink(linkName);
    };

    const renderContent = () => {
        switch (activeLink) {
            case 'about-sab':
                return <AboutComp />;
            case 'our-purpose':
                return <PurposeComp />;
            case 'our-values':
                return <ValuesComp />;
            // Add cases for other components, e.g.:
            // case 'our-purpose':
            //     return <PurposeComp />;
            // case 'our-values':
            //     return <ValuesComp />;
            // ... other cases ...
            default:
                return <AboutComp />; // Default to AboutComp if no match
        }
    };

    return (
        <div className="home-container">
            <div className="background-section" style={{ backgroundImage: `url(${background})` }}>
                {/* Secondary Navigation Bar */}
                <div className="secondary-nav">
                    <div className={`nav-item ${activeLink === 'about-sab' ? 'active' : ''}`} onClick={() => handleSetActiveLink('about-sab')}>
                        About DIT
                    </div>
                    <div className={`nav-item ${activeLink === 'our-purpose' ? 'active' : ''}`} onClick={() => handleSetActiveLink('our-purpose')}>
                        Our Purpose
                    </div>
                    <div className={`nav-item ${activeLink === 'our-values' ? 'active' : ''}`} onClick={() => handleSetActiveLink('our-values')}>
                        Our Values
                    </div>
                    <div className={`nav-item ${activeLink === 'board-of-directors' ? 'active' : ''}`} onClick={() => handleSetActiveLink('board-of-directors')}>
                        Board of Directors
                    </div>
                    {/* ... other nav items ... */}
                </div>
            </div>

            {mobileNav && (
                <div className="mobile-nav">
                    <select
                        value={activeLink}
                        onChange={(e) => handleSetActiveLink(e.target.value)}
                    >
                        <option value="about-sab">About DIT</option>
                        <option value="our-purpose">Our Purpose</option>
                        <option value="our-values">Our Values</option>
                        <option value="board-of-directors">Board of Directors</option>
                        {/* ... other options ... */}
                    </select>
                </div>
            )}

                <div className="cookie-consent">
                <p>
                    DIT uses cookies to give you the best possible experience on this website. 
                    Please read our <a href="/cookie-policy" className="cookie-policy-link">cookie policy</a>.
                <button className="close-cookie-consent">✕</button>
                </p>
            </div>
           <div className="home-content">
    <div className="dynamic-content">
        {renderContent()}
    </div>
    <div className="asides-container">
        <div className="content-aside">
            <p>Memorandum and Articles of Association of DIT</p>
            <a href="path_to_downloadable_resource">
                <p>DIT Bylaws <img src={down} alt="Download" /></p>
            </a>
        </div>
        <div className="content-aside2">
    <h2>Recent News</h2>
    <div className="title-bar"></div>
    
    {recentNews.map((story, index) => (
        <div key={index} className={`news-article ${index > 0 ? 'additional-news' : ''}`}>
            <Link to={`/news/${story.id}`} className="news-title-link">
                <h3 className="news-title">
                    <span className="news-category-indicator2"></span>
                    {story.title}
                </h3>
            </Link>
            <div className="news-info">
                <span className="news-date">{story.date}</span>
                <span className="news-category">{story.category}</span>
            </div>
        </div>
    ))}
    
    {/* The Read More News link will be hidden on desktop via CSS */}
    <Link to="/newsroom" className="read-more-news mobile-only">Read More News</Link>
</div>

    </div>
</div>

        </div>
    );
}

export default HomeComp;
