// MarketsComp.js
import React from 'react';
import './Markets.css'; // Make sure the CSS file path is correct
import MarketBackground from '../assets/markets.jpg'; // Assuming you're using the same background

const MarketsComp = () => {
    return (
        <div className="markets-container">
            <div className="markets-section" style={{ backgroundImage: `url(${MarketBackground})` }}></div>
            <div className="markets-content">
                <h1>Market Regulations</h1>
                <div className="title-bar"></div>

                <p className="markets-meta">
                    <span>First published: 08/05/2015</span> | 
                    <span>Last updated: 13/07/2023</span> | 
                    <a href="/updates">See all updates</a>
                </p>
                <hr/>
                <p>We regulate financial services markets, including exchanges and the issuers of securities, to ensure fair and transparent market practices. Our regulatory framework is designed to protect investors, maintain fair, efficient, and transparent markets, and reduce systemic risk.</p>
                <h2>Our Responsibilities</h2>
                <ul>
                    <li>Overseeing the conduct of market participants</li>
                    <li>Ensuring the integrity of financial products and services</li>
                    <li>Monitoring market transactions to prevent fraud and market abuse</li>
                    <li>Setting standards for corporate governance and disclosure</li>
                </ul>
                <h2>Regulatory Tools</h2>
                <p>Our regulatory toolkit includes:</p>
                <ul>
                    <li>Rulemaking, to create robust regulatory standards</li>
                    <li>Supervision, to oversee the operations of financial institutions</li>
                    <li>Enforcement, to act against violations of our regulations</li>
                    <li>Examination, to ensure compliance and assess the risk profile of entities</li>
                </ul>
                <h2>Collaboration with Other Regulators</h2>
                <p>We work closely with domestic and international regulatory bodies to coordinate regulations across different jurisdictions and to tackle challenges posed by the global nature of financial markets.</p>
                <h2>Stay Informed</h2>
                <p>To stay up-to-date with the latest regulatory developments, please subscribe to our newsletter or visit the regulatory updates section on our website.</p>
                <p>If you have any queries or require further information, please <a href="/contact">contact us</a>.</p>
            </div>
        </div>
    );
}

export default MarketsComp;
