import React, { useState } from 'react';
import { Typography, Container, Button, MenuItem, FormControl, Select, InputLabel, Box } from '@mui/material';
import './RegisterPart1.css';

const RegisterPart1 = ({ setCurrentStep  }) => {
    const [accountType, setAccountType] = useState('');

    const handleAccountTypeChange = (event) => {
        setAccountType(event.target.value);
    };

    const handleContinue = () => {
        if(accountType) { // Check if an account type is selected
            setCurrentStep(3); // Assuming step 2 is RegisterPart1 and step 3 is RegisterStep2
        } else {
            // Optionally, show an error or alert to select an account type
            alert("Please select an account type to continue.");
        }
    };


    return (
        <Container elevation={3} className="register-part2-paper">
            <Typography variant="h6" className="register-part2-step">
                Step 1 of 6: Getting started
            </Typography>
            <Typography variant="h5" className="register-part2-header">
                Your path to secure case management
            </Typography>
            <Typography paragraph className="register-part2-text">
                Registering your case with DIT ensures you have secure and confidential access to our financial integrity systems. We're dedicated to safeguarding your data as we navigate through your case.
            </Typography>
            <Typography paragraph className="register-part2-text">
                Start by selecting the type of account you wish to register. This helps us tailor our services to your specific needs and provide the most efficient support.
            </Typography>

            <FormControl fullWidth className="register-part2-formControl">
                <InputLabel id="account-type-select-label">Choose account type:</InputLabel>
                <Select
                    labelId="account-type-select-label"
                    id="account-type-select"
                    value={accountType}
                    label="Choose account type:"
                    onChange={handleAccountTypeChange}
                >
                    <MenuItem value="">
                        <em>- Please choose account -</em>
                    </MenuItem>
                    {/* Add more MenuItems for each account type */}
                    <MenuItem value={"consumer"}>Consumer Account</MenuItem>
                    <MenuItem value={"trading"}>Trading Account</MenuItem>
                    <MenuItem value={"business"}>Business Account</MenuItem>
                </Select>
            </FormControl>

            <Box className="register-part2-disclaimer">
                <Typography paragraph className="register-part2-text">
                    By proceeding, you agree to our terms and confirm that you have read our Data Protection Policy. DIT commits to the ethical handling of your personal information throughout your case's lifecycle.
                </Typography>
                <Typography paragraph className="register-part2-text">
                    Have questions? Learn more about <a href="#how-we-handle-cases" className="register-part2-link">how DIT handles cases</a> and the protections in place for you.
                </Typography>
            </Box>

            <Button variant="contained" color="primary" className="register-part2-button" onClick={handleContinue}>
                Continue
            </Button>
        </Container>
    );
};

export default RegisterPart1;
