// FirmsComponent.js
import React from 'react';
import './Firms.css'; // Ensure you have the correct path to your CSS file
import FirmsImage from '../assets/firms2.jpg';

const FirmsComponent = () => {
    return (
        <div className="firms-container">
            <div className="firms-section" style={{ backgroundImage: `url(${FirmsImage})` }}></div>
            <div className="firms-content">
                <h1>Fees and Levies</h1>
                <div className="title-bar"></div>

                <p className="firms-meta">
                    <span>First published: 08/05/2015</span> | 
                    <span>Last updated: 13/07/2023</span> | 
                    <a href="/updates">See all updates</a>
                </p>
                <hr/>
                <p>Find out about the fees we charge and what they cover, and the levies we collect for organisations that are part of the regulatory system.</p>
                <hr/>
                <p>We are independent of Government. To finance our work we charge fees to:</p>
                <ul>
                    <li>the firms we authorise</li>
                    <li>some other bodies such as recognised investment exchanges or registered firms</li>
                </ul>
                <p>There are 3 types of fee:</p>
                <ul>
                    <li>application – when you ask us to authorise your firm</li>
                    <li>change to permissions – when you want to change a permission for an authorised activity</li>
                    <li>annual (periodic) – which is payable each year</li>
                </ul>
                <p>We also collect fees and levies to pay for the costs of the:</p>
                <ul>
                    <li><a href="#">Payment Systems Regulator</a></li>
                    <li><a href="#">Prudential Regulation Authority</a></li>
                    <li><a href="#">Financial Ombudsman Service</a></li>
                    <li><a href="#">Financial Services Compensation Scheme</a></li>
                    <li><a href="#">Money and Pensions Service</a> (Financial Guidance levies)</li>
                    <li><a href="#">Financial Reporting Council</a></li>
                    <li><a href="#">Illegal Money Lending Team</a></li>
                    <li><a href="#">Economic Crime Levy (ECL)</a></li>
                </ul>
            </div>
        </div>
    );
}

export default FirmsComponent;
