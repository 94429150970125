import React from 'react';
import './Values.css'; // Make sure this path is correct

const ValuesComp = () => {
    return (
        <div className="values-container">
            <h1>Our Values</h1>
            <div className="title-bar"></div>

            <p>
                At Diversified International Trade (DIT), our core values are the bedrock of our business practices and partnerships. We uphold the highest standards of integrity, transparency, and excellence in all aspects of our operations.
            </p>
            <p>
                <strong>Integrity:</strong> We conduct our business with unwavering integrity, ensuring fairness and trust in every transaction.
            </p>
            <p>
                <strong>Sustainability:</strong> We are committed to sustainability, both environmentally and economically, striving to make a positive impact on the planet and its people.
            </p>
            <p>
                <strong>Innovation:</strong> We embrace innovation, continually seeking out new and better ways to serve our clients and advance global trade.
            </p>
            <p>
                These values guide our approach to business and define our commitment to contributing to a better world. Our dedication to these principles is unwavering as we work towards a future of sustainable and inclusive growth.
            </p>
        </div>
    );
}

export default ValuesComp;
