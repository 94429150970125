// AccountComp.js

import React, { useContext, useState } from 'react';
import SideNav from './Sidenav/SideNav';
import { AuthContext } from '../Context/AuthContext'; // Adjust the import path as per your file structure
import './Account.css'
import MainDashComp from './DashMain/DashMain';
import TransMainComp from './TrasnMain/TransMain';
import InboxMainComp from './InboxMain/InboxMain';
import ProfileMainComp from './ProfileMain/ProfileMain';
import TransferOptionsComp from './TransferOptions';
import UserAccounts from './DITAccs/DitAccs';
import DashboardFooterComp from './Footer/DashFoot';
import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

const AccountComp = () => {
    const { user } = useContext(AuthContext); // Access user data from context
    const [activeComponent, setActiveComponent] = useState('Dashboard'); // State to track the active component
    const handleActiveComponentChange = (componentName) => {
        setActiveComponent(componentName);
    };
    const [pendingAmount, setPendingAmount] = useState(0);

    const handleUpdatePendingAmount = (amount) => {
        setPendingAmount(amount);
    };

    const [unreadCount, setUnreadCount] = useState(0);

    const getGreetingBasedOnTime = () => {
        const hour = new Date().getHours(); // Gets the current hour (0-23)
        if (hour < 12) return 'Good morning';
        if (hour < 18) return 'Good afternoon';
        return 'Good evening';
    };

    

    const renderComponent = () => {
        switch (activeComponent) {
            case 'Dashboard':
                return <MainDashComp />;
            case 'Transactions': // Add a new case for Transactions
                return <TransMainComp />;
            case 'Inbox': // Add a new case for Transactions
                return <InboxMainComp setUnreadCount={setUnreadCount} />;
            case 'Profile': // Add a new case for Transactions
                return <ProfileMainComp />;
            // ... other cases
            default:
                return <MainDashComp />;
        }
    };


    return (
        <div className="account-page">
        <SideNav onActiveItemChange={handleActiveComponentChange} unreadCount={unreadCount} />
            <div className="account-content">
            <Box sx={{ mb: 4, mt: 2 }}> {/* Adjust margin as needed */}
    <Typography variant="h4" component="h2" gutterBottom>
        {getGreetingBasedOnTime()}, {user?.first_name}.
    </Typography>
</Box>
<Divider sx={{ my: 3 }} />

                {/* Render the active component here */}
                {renderComponent()}
               
                {(activeComponent === 'Dashboard' || activeComponent === 'Transactions') && 
<TransferOptionsComp className={activeComponent} />}
            <DashboardFooterComp/>
            </div>
            <div>
            </div>
        </div>
    );
};

export default AccountComp;
