import React from 'react';
import './Security.css'
import SecurityImage from '../assets/vision.jpg';
import EncryptionIcon from '../assets/encryption.png';
import CybersecurityIcon from '../assets/cyber-security.png';
import TipsIcon from '../assets/idea.png';

const SecurityComp = () => {
    return ( 
        <div className="security-container">
            <div className="security-section" style={{ backgroundImage: `url(${SecurityImage})` }}></div>
            <div className="security-content">
                <h1>Security and Safety</h1>
                <div className="title-bar"></div>

                <p className="security-meta">
                    <span>First published: 08/05/2016</span> | 
                    <span>Last updated: 13/07/2023</span> | 
                    <a href="/updates">See all updates</a>
                </p>
                <hr/>
                <p>Ensuring the security and safety of our clients' data and assets is our top priority. Our comprehensive approach to security encompasses everything from data protection to personal safety advice.</p>
                <hr/>
                
                <div className="security-section-item">
                    <img src={EncryptionIcon} alt="Encryption" className="security-icon"/>
                    <div>
                        <h2>Data Protection and Privacy</h2>
                        <p>Our commitment to data protection and privacy is unwavering. We employ advanced encryption and security protocols to safeguard your information at all times. Learn about our strict data handling policies and how we ensure compliance with global data protection regulations.</p>
                        <p>From multi-factor authentication to regular audits, our security framework is designed to protect personal and financial data. We are transparent in our practices and offer our clients the highest level of confidentiality.</p>
                    </div>
                </div>

                <div className="security-section-item">
                    <img src={CybersecurityIcon} alt="Cybersecurity" className="security-icon"/>
                    <div>
                        <h2>Cybersecurity Measures</h2>
                        <p>We continuously update our cybersecurity measures to guard against the latest threats, ensuring your data remains secure against unauthorized access and cyber attacks. Discover how we stay ahead of cyber threats with innovative technologies and constant vigilance.</p>
                        <p>Our team of cybersecurity experts regularly conducts penetration testing and security training. We also collaborate with global security agencies to stay updated on emerging threats and best practices.</p>
                    </div>
                </div>

                <div className="security-section-item">
                    <img src={TipsIcon} alt="Security Tips" className="security-icon"/>
                    <div>
                        <h2>Tips for Personal Security</h2>
                        <ul>
                            <li>Use strong, unique passwords for all accounts.</li>
                            <li>Be cautious of phishing emails and suspicious links.</li>
                            <li>Regularly update your software to protect against vulnerabilities.</li>
                            <li>Enable two-factor authentication for added security.</li>
                            <li>Be aware of the latest scams and how to avoid them.</li>
                        </ul>
                        <p>Stay informed and proactive about your digital security. Regularly review your security settings and be vigilant about sharing personal information.</p>
                    </div>
                </div>

                <h2>Frequently Asked Questions</h2>
                <div className="security-faq">
                    <p><strong>How often do you update your security protocols?</strong><br/>Our security protocols are reviewed and updated regularly to respond to new threats and ensure the highest level of protection.</p>
                    <p><strong>What should I do if I suspect a security breach?</strong><br/>If you suspect any breach or unusual activity, contact our support team immediately. Quick response is crucial for security incidents.</p>
                </div>

                <p>For more detailed information on our security policies and tips, please <a href="/security">visit our security page</a>.</p>
            </div>
            <div className="latest-updates">
                    <div className="latest-consultations">
                        <h2>Latest Consultations</h2>
                        <ul>
                            <li>CP24/1: Financial Services Compensation Scheme - Management Expenses Levy Limit 2024/25</li>
                            <li>CP23/31: Primary Markets Effectiveness Review: Feedback to CP23/10 and detailed proposals for listing rules reforms</li>
                            <li>CP23/32: Improving transparency for bond and derivatives markets</li>
                            <li>CP23/33: Consultation on payments to data providers and DRSP forms including Policy Statement for the framework for UK consolidated tape (CP23/15)</li>
                            <li>CP23/29: Mynediad at arian parod</li>
                        </ul>
                        <a href="/consultations">Show all consultations</a>
                    </div>
                    <div className="latest-policy-guidance">
                        <h2>Latest Policy and Guidance</h2>
                        <ul>
                            <li>PS24/1: Temporary changes to handling rules for motor finance complaints</li>
                            <li>PS23/19: Margin requirements for non-centrally cleared derivatives: Amendments to BTS 2016/2251</li>
                            <li>PS23/18: Smarter Regulatory Framework: The Insurance Distribution Directive</li>
                            <li>Engagement feedback on the new public offers and admissions to trading regime</li>
                            <li>PS23/17: Remuneration: enhancing proportionality for small firms</li>
                        </ul>
                        <a href="/policy">Latest policy and guidance</a>
                    </div>
                </div>
        </div>
    );
}
 
export default SecurityComp;
