import React, { useState, useEffect } from 'react';
import {Container, Typography, Paper, TextField, Button, Box, MenuItem, FormGroup, FormControlLabel, Checkbox,InputAdornment, ToggleButtonGroup, ToggleButton } from '@mui/material';
import './RegisterStep2.css';
import countries from './Countries.json';
import axios from 'axios'; // Make sure to install axios if you haven't already
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { getCountryCallingCode } from 'libphonenumber-js';
import { useNavigate } from 'react-router-dom';


const RegisterStep2 = ({ setCurrentStep }) => {
    const [loading, setLoading] = useState(true); // State to manage loading status
    const [defaultCountry, setDefaultCountry] = useState('');
    const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [ipAddress, setIpAddress] = useState('');
    const [formErrors, setFormErrors] = useState({});

    const apiUrl = process.env.REACT_APP_API_URL;


    const navigate = useNavigate();


    const [personalDetails, setPersonalDetails] = useState({
        first_name: '',
        last_name: '',
        email: '',
        title: '',
        dobDay: '',
        dobMonth: '',
        dobYear: '',
        addressType: 'overseas', // 'uk', 'overseas', or 'bfpo'
        address: '',
        postcode: '',
        country: '',
        phone_number: '',

        // Add other fields as necessary
    });

    const handleBack = () => {
        navigate('/login');
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
        setPasswordError(false); // Reset error state on password change
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        setPasswordError(false); // Reset error state on confirm password change
    };
    
    const validatePasswords = () => {
        if (password !== confirmPassword || !password) {
            setPasswordError(true);
            return false;
        }
        return true;
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        let newValue = value;
    
        // Check if the field is 'dobDay' or 'dobMonth' and ensure the value is a string
        if (name === 'dobDay' || name === 'dobMonth') {
            newValue = String(value);
        }
    
        setPersonalDetails(prevDetails => ({
            ...prevDetails,
            [name]: newValue
        }));
    };

    const handleAddressTypeChange = (event, newAddressType) => {
        if (newAddressType !== null) {
            setPersonalDetails(prevDetails => ({
                ...prevDetails,
                addressType: newAddressType
            }));
        }
    };

    function getFullPhoneNumber(phoneNumber, countryCode) {
        // If phoneNumber is empty and countryCode is available, return the calling code
        if (!phoneNumber && countryCode) {
            return `+${getCountryCallingCode(countryCode)}`;
        }
        // If phoneNumber already starts with '+', return as is
        if (phoneNumber.startsWith('+')) {
            return phoneNumber;
        }
        // Otherwise, prefix the calling code
        const callingCode = getCountryCallingCode(countryCode);
        return `+${callingCode}${phoneNumber}`;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate() || !hasAgreedToTerms) {
            // If validation fails or terms not agreed, stop here
            alert("Please correct the errors before proceeding.");
            return;
        }
        if (!hasAgreedToTerms) {
            alert("You must agree to the terms and policies before proceeding.");
            return;
        }
    
        if (!validatePasswords()) {
            return;
        }
    
        const dob = `${personalDetails.dobYear}-${String(personalDetails.dobMonth).padStart(2, '0')}-${String(personalDetails.dobDay).padStart(2, '0')}`;
    
        const registrationData = {
            ...personalDetails,
            email: personalDetails.email.toLowerCase(),
            password,
            dob: dob,
            ip_address: ipAddress
        };
        delete registrationData.dobDay;
        delete registrationData.dobMonth;
        delete registrationData.dobYear;
    
        try {
            const response = await axios.post(`${apiUrl}/registerttt/`, registrationData, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization header if needed for JWT tokens
                },
            });
            console.log('Registration successful:', response.data);
            // Redirect to the login page after successful registration
            navigate('/login');
        } catch (error) {
            console.error('Registration failed:', error.response.data);
            // Handle errors (e.g., show an error message)
        }
    };
    const validate = () => {
        let tempErrors = {};
        // Existing validations
        tempErrors.first_name = personalDetails.first_name ? '' : 'First name is required.';
        tempErrors.last_name = personalDetails.last_name ? '' : 'Last name is required.';
        tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(personalDetails.email) ? '' : 'Email is invalid.';
        tempErrors.password = password.length >= 8 ? '' : 'Password must be at least 8 characters.';
        tempErrors.confirmPassword = confirmPassword === password ? '' : 'Passwords do not match.';
        tempErrors.dobDay = personalDetails.dobDay ? '' : 'Day of birth is required.';
        tempErrors.dobMonth = personalDetails.dobMonth ? '' : 'Month of birth is required.';
        tempErrors.dobYear = personalDetails.dobYear ? '' : 'Year of birth is required.';
        tempErrors.addressType = personalDetails.addressType ? '' : 'Address type is required.';
        tempErrors.address = personalDetails.address ? '' : 'Address is required.';
        tempErrors.postcode = personalDetails.postcode ? '' : 'Postcode is required.';
        tempErrors.country = personalDetails.country ? '' : 'Country is required.';
        // Basic phone number validation
        tempErrors.phone_number = /^\+\d{7,}$/.test(personalDetails.phone_number) ? '' : 'A valid phone number is required.';
    
        setFormErrors(tempErrors);
    
        return Object.values(tempErrors).every(x => x === '');
    };
    const days = Array.from({ length: 31 }, (_, i) => i + 1); // Generates numbers from 1 to 31
const months = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];
const currentYear = new Date().getFullYear();
const years = Array.from({ length: 100 }, (_, i) => currentYear - i); // Last 100 years


const fetchIPAndCountry = async () => {
    try {
        const response = await fetch('http://ip-api.com/json');
        const data = await response.json();
        setDefaultCountry(data.countryCode);
        setIpAddress(data.query); // Save the IP address here
        setPersonalDetails((prevDetails) => ({
            ...prevDetails,
            country: data.countryCode,
        }));
    } catch (error) {
        console.error('Error fetching IP and country:', error);
        // Fallback fetch from ipinfo.io
        try {
            const ipInfoResponse = await axios.get('https://ipinfo.io/json');
            const ipInfoData = ipInfoResponse.data;
            setDefaultCountry(ipInfoData.country);
            setIpAddress(ipInfoData.ip); // Save the IP address here
            setPersonalDetails((prevDetails) => ({
                ...prevDetails,
                country: ipInfoData.country,
            }));
        } catch (fallbackError) {
            console.error('Fallback error:', fallbackError);
        }
    } finally {
        setLoading(false); // Ensure we set loading to false after the fetch
    }
};


useEffect(() => {
    fetchIPAndCountry();
    // When defaultCountry is set, update the mobile number with the calling code
    if (defaultCountry) {
        setPersonalDetails(prevDetails => ({
            ...prevDetails,
            phone_number: getFullPhoneNumber(prevDetails.phone_number, defaultCountry)
        }));
    }
// You need to be careful with dependencies here to avoid an infinite loop
// Only re-run this effect if defaultCountry changes.
}, [defaultCountry]);


    return (
        <Container elevation={3} className="register-step2-paper">
            <Typography variant="h6" className="register-step2-header">
                Step 2 of 6: Personal Details
            </Typography>
            <form className="register-step2-form" onSubmit={handleSubmit}>
                <Box className="register-step2-section">
                    <Typography variant="subtitle1" gutterBottom>About you</Typography>
                    <TextField
        select
        label="Title"
        name="title"
        value={personalDetails.title}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant='filled'

    >
        <MenuItem value="Mr">Mr.</MenuItem>
        <MenuItem value="Ms">Ms.</MenuItem>
        <MenuItem value="Mrs">Mrs.</MenuItem>
        <MenuItem value="Dr">Dr.</MenuItem>
    </TextField>
    <TextField
    label="First name *"
    name="first_name"
    value={personalDetails.first_name}
    onChange={handleChange}
    error={!!formErrors.first_name}
    helperText={formErrors.first_name || "Enter your first name"}
    fullWidth
    margin="normal"
    variant='filled'
/>
                    <TextField
                        label="Last name *"
                        name="last_name"
                        value={personalDetails.last_name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant='filled'
                        error={!!formErrors.last_name}
                        helperText={formErrors.last_name || "Enter your last name"}

                    />
                    <TextField
                        label="Email *"
                        name="email"
                        value={personalDetails.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant='filled'
                        error={!!formErrors.email}
                        helperText={formErrors.email || "Enter a valid email address"}
                    />



                    <TextField
            label="Mobile *"
            name="phone_number"
            value={personalDetails.phone_number}
            onChange={(e) => {
                // Here you could sanitize and set the mobile number as needed
                const sanitizedValue = e.target.value.replace(/[^0-9+]/g, '');
                setPersonalDetails({ ...personalDetails, phone_number: sanitizedValue });
            }}
            fullWidth
            error={!!formErrors.phone_number}
            helperText={formErrors.phone_number || "Include your country code"}
            margin="normal"
            variant="filled"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PhoneInput
                            international
                            countryCallingCodeEditable={false}
                            value={personalDetails.phone_number}
                            onChange={value => setPersonalDetails({ ...personalDetails, phone_number: getFullPhoneNumber(value, defaultCountry) })}
                            defaultCountry={defaultCountry}
                            country={defaultCountry}
                            style={{ width: 'auto' }}
                        />
                    </InputAdornment>
                ),
            }}
        />



        <Box className="dob-container" sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
    <TextField
        select
        label="Day*"
        name="dobDay"
        value={personalDetails.dobDay}
        onChange={handleChange}
        sx={{ width: '80px' }} // Adjust width as necessary
        margin="normal"
        variant='filled'
        error={!!formErrors.dobDay}
         helperText={formErrors.dobDay || "Day"}


    >
        {/* Generate day options */}
        {[...Array(31)].map((_, index) => (
            <MenuItem key={index} value={index + 1}>
                {index + 1}
            </MenuItem>
        ))}
    </TextField>
    <TextField
        select
        label="Month *"
        name="dobMonth"
        value={personalDetails.dobMonth}
        onChange={handleChange}
        sx={{ width: '150px' }} // Adjust width as necessary
        margin="normal"
        variant='filled'
        error={!!formErrors.dobMonth}
        helperText={formErrors.dobMonth || "Month"}


    >
        {/* Generate month options */}
        {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, index) => (
            <MenuItem key={month} value={index + 1}>
                {month}
            </MenuItem>
        ))}
    </TextField>
    <TextField
        select
        label="Year *"
        name="dobYear"
        value={personalDetails.dobYear}
        onChange={handleChange}
        sx={{ width: '100px' }} // Adjust width as necessary
        margin="normal"
        variant='filled'
        error={!!formErrors.dobYear}
        helperText={formErrors.dobYear || "Year"}


    >
        {/* Generate year options */}
        {[...Array(100)].map((_, index) => {
            const year = new Date().getFullYear() - index;
            return (
                <MenuItem key={year} value={year} >
                    {year}
                </MenuItem>
            );
        })}
    </TextField>
</Box>
                </Box>
                
                <Box className="register-step2-section">
                    <Typography variant="subtitle1" gutterBottom>Address</Typography>
                    <ToggleButtonGroup
                        color="primary"
                        value={personalDetails.addressType}
                        exclusive
                        onChange={handleAddressTypeChange}
                        fullWidth
                        sx={{ marginBottom: '16px' }}
                        
                    >
                        <ToggleButton value="overseas">I have an overseas address</ToggleButton>
                        <ToggleButton value="uk">I have a UK address</ToggleButton>
                    </ToggleButtonGroup>
                    {personalDetails.addressType === 'uk' ? (
                        <TextField
                            label="postcode *"
                            name="postcode"
                            value={personalDetails.postcode}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='filled'
                            error={!!formErrors.postcode}
                            helperText={formErrors.postcode}

                        />
                    ) : (
                        <>
                            {personalDetails.addressType === 'overseas' && (
         <TextField
         select
         label="Country *"
         name="country"
         value={personalDetails.country}
         onChange={handleChange}
         fullWidth
         margin="normal"
         variant="filled"
         error={!!formErrors.country}
        helperText={formErrors.country}
         disabled={loading} // Disable the field while loading the default country
     >
            {countries.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                    {country.name}
                </MenuItem>
            ))}
        </TextField>
    )}
                            <TextField
                                label="address *"
                                name="address"
                                value={personalDetails.address}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                variant='filled'
                                error={!!formErrors.address}
                                helperText={formErrors.address}

                            />
                            <TextField
                            label="postcode *"
                            name="postcode"
                            value={personalDetails.postcode}
                            onChange={handleChange}
                            fullWidth
                            margin="normal"
                            variant='filled'
                            error={!!formErrors.postcode}
                            helperText={formErrors.postcode}

                        />
                        </>
                    )}
                </Box>
                <Box className="register-step2-section">
                <Typography variant="subtitle1" gutterBottom>Security</Typography>
                <TextField
                    label="Password *"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    error={!!formErrors.password}
                    helperText={formErrors.password}
                />
                <TextField
                    label="Confirm Password *"
                    name="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    fullWidth
                    margin="normal"
                    variant="filled"
                    error={!!formErrors.confirmPassword}
                    helperText={formErrors.confirmPassword}
                />
            </Box>
                <FormGroup>
  <FormControlLabel
    control={
      <Checkbox
        checked={hasAgreedToTerms}
        onChange={(e) => setHasAgreedToTerms(e.target.checked)}
        name="agreedToTerms"
      />
    }
    label="I agree to the Terms of Service and Privacy Policy."
  />
  
</FormGroup>
                <Box className="register-step2-action">
                    <Button variant="outlined" onClick={() => setCurrentStep(prevStep => prevStep - 1)}>
                        Back
                    </Button>
                    {/* <Button variant="outlined" onClick={() => setCurrentStep(prevStep => prevStep - 1)}>
                        Back
                    </Button> */}
                    <Button variant="contained" color="primary" type="submit">
                        Continue
                    </Button>
                </Box>
            </form>
        </Container>
    );
};

export default RegisterStep2;
