import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Success icon

const UploadButton = styled(IconButton)({
  borderRadius: '50%',
  border: '2px dashed gray',
  width: '100px',
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  transition: 'border 0.3s ease-in-out, transform 0.3s ease-in-out',
  '&:hover': {
    borderColor: 'primary.main',
    backgroundColor: 'rgba(25, 118, 210, 0.04)',
    transform: 'scale(1.05)',
  },
  '& svg': {
    fontSize: '3rem',
    color: 'primary.main',
  },
  '&:hover svg': {
    color: 'secondary.main',
  },
});

const IDPhotoComp = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false); // New state for upload success
  const [uploadError, setUploadError] = useState('');

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return; // Do nothing if no file is selected
    }
    const apiUrl = process.env.REACT_APP_API_URL;

    setIsUploading(true);
    setUploadError(''); // Reset upload error state

    const formData = new FormData();
    formData.append('photo_id', file); // Match the key with your backend

    try {
      const response = await fetch(`${apiUrl}/upload_photo_id/`, { // Adjust this URL as necessary
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : ''}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload image. Please try again.');
      }

      // Handle success
      setUploadSuccess(true); // Set upload success to true
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError('Failed to upload image. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box>
      {uploadSuccess ? (
        <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />} sx={{ mb: 2 }}>
          <AlertTitle>Upload Successful</AlertTitle>
          Thank you for submitting your document. It is currently under review.
        </Alert>
      ) : (
        <Alert severity="error" icon={<PhotoCameraIcon fontSize="inherit" />} sx={{ mb: 2 }}>
          <AlertTitle>Document Submission Required</AlertTitle>
          To continue with your registration, please submit a photo ID or passport.
        </Alert>
      )}

      {!uploadSuccess && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <UploadButton color="primary" component="label" disabled={isUploading}>
            {isUploading ? (
              <CircularProgress size={48} color="primary" />
            ) : (
              <PhotoCameraIcon />
            )}
            <input type="file" hidden onChange={handleImageUpload} accept="image/*" disabled={isUploading} />
          </UploadButton>
          <Typography variant="caption" sx={{ mt: 1 }}>
            Click the camera icon to upload your photo ID or passport.
          </Typography>
          {uploadError && <Typography color="error" sx={{ mt: 2 }}>{uploadError}</Typography>}
        </Box>
      )}

      {!uploadSuccess && (
        <Typography variant="body1">
          You can upload the documents securely through your account settings. Please ensure the photo is clear and all information is visible.
        </Typography>
      )}
    </Box>
  );
};

export default IDPhotoComp;