import React, { Component } from 'react';
import Protect from '../../../assets/firms2.jpg';
import './ProtectCon.css'
import GoBackButton from '../../../utils/Goback';
const ProtectComponent = () => {
    return ( 
        <div className="protect-container">
            <GoBackButton/>
        <div className="protect-section" style={{ backgroundImage: `url(${Protect})` }}></div>
        <div className="protect-content">
            <h1>How We Protect Consumers</h1>
            <div className="title-bar"></div>
            <p>From bank accounts to mortgages, credit cards, loans, savings, pensions, and investments, virtually every adult in the UK is a consumer of financial services. One of our operational objectives is to use our powers over firms and markets to protect consumers.</p>
            <hr />
            <h2>Consumer Duty</h2>
            <p>Our Consumer Duty sets a higher standard of consumer protection in financial services. This means consumers should expect:</p>
            <ul>
                <li>the support they need, when they need it</li>
                <li>communications they understand</li>
                <li>products and services that meet their needs and offer fair value</li>
            </ul>
            <p>From 31 July 2023, all products and services on sale in retail markets must deliver the outcomes set by the Duty. The same applies to older products that aren’t on sale anymore from 31 July 2024.</p>
            <hr />
            <h2>Monitoring Firms</h2>
            <p>We monitor which firms and individuals can enter the financial markets, making sure they meet our standards before we authorise them. Firms must continue to meet these standards after we’ve authorised them, and we supervise how they work to make sure they do. If we find that firms aren’t following our rules, we act. This may mean imposing fines, stopping them from trading, or securing compensation for consumers.</p>
            <hr />
            <h2>Understanding Risks</h2>
            <p>We protect people by helping them understand some of the most common risks they might come across when using financial services. We publish warnings about firms that are doing business without our authorisation, and we encourage consumers to report scams, potential harm, or bad conduct to us.</p>
            <p>Our ScamSmart campaign targets individuals who are most at risk of investment fraud and pension scams. Our InvestSmart campaign also warns newer investors about the risks of investing online.</p>
            <hr />
            <h2>Healthy Competition</h2>
            <p>Our work to protect consumers also helps support healthy competition, integrity in the financial system, and the sustainable growth and international competitiveness of the UK. By maintaining high standards in consumer protection, we aim to create a foundation of trust.</p>
            <p>People need to know whether they can trust the firms they buy from and whether they’re protected if something goes wrong. This gives them the confidence to make choices and switch product providers.</p>
            <p>When competition works well, consumers are empowered as well as informed. Firms compete to win their business based on service, quality, price, and innovation.</p>
        </div>
    </div>
);
     
}
 
export default ProtectComponent;