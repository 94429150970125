import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, List, ListItem, ListItemIcon, ListItemText, Alert, Link } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LaunchIcon from '@mui/icons-material/Launch';
import RegistrationProcess from './Signup/RegisterSteps';
import './RegisterPage.css'
import RegisterPart1 from './Signup/SignUp1/RegisterPart1';
import RegisterStep2 from './Signup/Signup2/RegisterStep2';
import LoginComp from '../Login/LoginPage';
const RegisterComponent = () => {

    const [currentStep, setCurrentStep] = useState(1);

    

  const handleContinueClick = () => {
   
        setCurrentStep(2);
    
};
useEffect(() => {
    // Scroll to the top of the page when the current step changes.
    window.scrollTo(0, 0);
}, [currentStep]); //

    return (
        <Container maxWidth="sm" className="register-container">
            {currentStep === 1 && (
                <Container className='register-sub-container' elevation={3} sx={{ p: 4 }}>
                    <Typography className='typo-register' variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                        Digital Integrity Trust (DIT) Case Registration
                    </Typography>
                    <Typography className='typo-register' variant="body1" sx={{ mt: 2 }}>
                        Begin the process of securing your case with our online management portal. This is the first step towards reclaiming control over fraudulently used accounts and assets.
                    </Typography>
                    <Alert severity="warning" sx={{ mt: 2 }}>
                        Registration processing time is approximately 6 working days. Activation of certain functionalities may vary based on case complexity.
                    </Alert>
                    <Typography className='typo-register' variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
                        Registration Requirements
                    </Typography>
                    <List className='register-main-list'>
                        {["Full legal name", "Address and postal code", "Valid email address", "Mobile phone number", "Detailed case information"].map(item => (
                            <ListItem key={item}>
                                <ListItemIcon>
                                    <CheckCircleOutlineIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                    <Box sx={{ mt: 4, bgcolor: 'background.paper', p: 2, borderLeft: 4, borderColor: 'primary.main' }}>
                        <Typography className='typo-register' variant="body2">
                            <InfoOutlinedIcon color="info" sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Be prepared to provide detailed information regarding your case for a thorough investigation and case management.
                        </Typography>
                    </Box>
                    <Typography className='typo-register' variant="h6" component="h3" sx={{ mt: 2, mb: 1 }}>
                        Next Steps
                    </Typography>
                    <List>
                        <ListItem className="register-list-item">
                            <ListItemIcon>
                                <FiberManualRecordIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText primary={<Link href="/login" underline="hover">Already registered?</Link>} />
                            <Link href="/login" underline="hover" className="register-link">
                                <LaunchIcon fontSize="small" />
                            </Link>
                        </ListItem>
                        <ListItem className="register-list-item">
                            <ListItemIcon>
                                <FiberManualRecordIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText>
                                <span onClick={handleContinueClick} style={{ textDecoration: 'underline', color: '#007bff', cursor: 'pointer' }}>
                                    Register
                                </span>
                                <Link href="/register" underline="hover" style={{ marginLeft: '5px' }}>
                                    <LaunchIcon fontSize="small" />
                                </Link>
                            </ListItemText>
                        </ListItem>
                    </List>
                    <Typography className='typo-register' variant="body1" sx={{ mt: 2 }}>
                        Leverage our sophisticated portal to actively manage and monitor your case progress after successful registration.
                    </Typography>
                    <Box sx={{ mt: 4, bgcolor: 'background.paper', p: 2, borderLeft: 4, borderColor: 'primary.main' }}>
                        <Typography className='typo-register' variant="body2">
                            <InfoOutlinedIcon color="primary" sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Proceeding with the registration indicates your acceptance of our Terms of Service and Privacy Policy. We are committed to safeguarding your data and privacy.
                        </Typography>
                    </Box>
                </Container>
            )}
            {currentStep === 2 && (
                <div>
                    <RegisterPart1 setCurrentStep={setCurrentStep}/>
                </div>
            )}
            {currentStep === 3 && (
                <div>
                    <RegisterStep2 setCurrentStep={setCurrentStep}/>
                </div>
            )}
        </Container>
    );
};

export default RegisterComponent;