import React from 'react';
import MarketIntegrityImage from '../../../assets/about.jpg'
import './Int.css'; // Make sure to create and style this CSS file accordingly
import GoBackButton from '../../../utils/Goback';
const MarketIntegrityComponent = () => {
    return (
        <div className="market-integrity-container">
                        <GoBackButton/>

            <div className="market-integrity-section" style={{ backgroundImage: `url(${MarketIntegrityImage})` }}></div>
            <div className="market-integrity-content">
                <h1>Enhancing Market Integrity</h1>
                <div className="title-bar"></div>
                <p>
                    Ensuring the integrity of the financial market is paramount for sustaining a healthy and successful financial system. Our organization is dedicated to upholding the highest standards of market conduct, transparency, and fairness, safeguarding the system's integrity for all participants.
                </p>
                <hr />
                <h2>Transparency and Fair Practices</h2>
                <p>
                    We advocate for clear and transparent communication within the financial markets, fostering an environment where every transaction is conducted openly and fairly. This commitment to transparency helps to build trust among market participants and contributes to a more stable financial system.
                </p>
                <hr />
                <h2>Regulatory Compliance and Oversight</h2>
                <p>
                    Through rigorous oversight and enforcement of compliance standards, we ensure that financial institutions and market participants adhere to established rules and regulations, maintaining the market's integrity and protecting investors and consumers from malpractice.
                </p>
                <hr />
                <h2>Educating and Empowering Participants</h2>
                <p>
                    A well-informed participant is an empowered one. We dedicate resources to educating consumers and investors about their rights and the risks involved in market activities, enabling them to make more informed decisions and better protect their interests.
                </p>
                <hr />
                <div className="button-container">
                </div>
            </div>
        </div>
    );
}

export default MarketIntegrityComponent;
