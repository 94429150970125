import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import DITlogo from '../../assets/dsa-logo.png'; // Adjust this import as needed
import QRCodeImage from '../../assets/QR-code.webp'; // Adjust this import as needed

const DashboardFooterComp = () => {
    return (
        <Box
            component="footer"
            sx={{
                borderTop: 1,
                borderColor: 'divider',
                py: 2,
                px: 3,
                mt: 4,
                backgroundColor: 'inherit',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Container maxWidth="lg">
                <Grid container>
                    <Grid item xs={12} md={4} sx={{ textAlign: 'left' }}>
                        <Typography variant="body2">+44 2037 726 920</Typography>
                        <Typography variant="body2">info@ditrecoveryportal.org</Typography>
                        <Typography variant="body2">
                            &copy; {new Date().getFullYear()} International Financial Claims and Compensation Authority
                        </Typography>
                        <Typography variant="body2">
                            Company House Registration Number: 15274505
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Box
                            component="img"
                            src={QRCodeImage}
                            alt="QR Code"
                            sx={{ height: '80px', marginRight: 2 }}
                        />
                        <Box
                            component="img"
                            src={DITlogo}
                            alt="Department for International Trade"
                            sx={{ height: '90px' }}
                        />
                    </Grid>
                    <Grid item md={4} sx={{ display: { xs: 'none', md: 'block' } }}>
                        {/* Empty Grid item for spacing purposes */}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default DashboardFooterComp;
