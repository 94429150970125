import React, { createContext, useEffect, useState } from 'react';

import { jwtDecode } from "jwt-decode";

import { useNavigate } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import SnackbarContent from '@mui/material/SnackbarContent';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    let [authTokens, setAuthTokens] = useState(() => localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null)
    const [user, setUser] = useState(() => localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null)
  
    const [status, setStatus] = useState(() => localStorage.getItem('status') || '');
    const apiUrl = process.env.REACT_APP_API_URL;

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [loading, setLoading] = useState(false)

    const [unreadCount, setUnreadCount] = useState(0);

    const navigate = useNavigate()
function LoadingSpinner() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress />
    </div>
  );
}
    const fetchStatus = async (tokens) => {
        if (tokens) {
            try {
                const response = await fetch(`${apiUrl}/fetch_status/`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${tokens.access}`,
                        'Content-Type': 'application/json'
                    },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    setStatus(data.status);
                    localStorage.setItem('status', data.status);
                    localStorage.setItem('total_pending', data.total_pending);
                    localStorage.setItem('total_generated', data.total_generated);
                    setUnreadCount(data.unread_messages_count);
                } else {
                    // Assuming the response includes a specific error message or status code for expired tokens
                    const data = await response.json();
                    if (!response.ok && (data.error === 'Token is expired' || response.status === 401)) {
                        throw new Error('Session expired. Please log in again.'); // Specific error for token expiration
                    } else {
                        throw new Error(data.error || 'Failed to fetch status.');
                    }
                }
            } catch (error) {
                console.error('Error fetching status:', error);
                setSnackbarMessage(error.toString());
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                if (error.message === 'Session expired. Please log in again.') {
                    logoutUser(); // Log out the user if the token has expired
                }
            }
        }
    };

    const updateStatus = async (newStatus) => {
        // Implement logic to update status in the backend
        // On success, update status in context and localStorage
        setStatus(newStatus);
        localStorage.setItem('status', newStatus);
    };

    const loginUser = async (payload) => {
        setLoading(true);
        try {
            const response = await fetch(`${apiUrl}/token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
    
            const data = await response.json();
    
            if (response.ok) {
                setAuthTokens(data);
                setUser(jwtDecode(data.access));
                localStorage.removeItem('status');
                localStorage.removeItem('total_pending');
                localStorage.removeItem('total_generated');
                localStorage.setItem('authTokens', JSON.stringify(data));
                await fetchStatus(data);
                navigate('/account');
                window.scrollTo(0, 0);

                setSnackbarMessage('Login successful');
                setSnackbarSeverity('success');
                await fetchStatus(data)
            } else {
                // Use the server-provided error message if available
                setSnackbarMessage(data.detail || 'An error occurred during login');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.error(error);
            setSnackbarMessage('An unknown error occurred');
            setSnackbarSeverity('error');
        } finally {
            setLoading(false); // End loading

            setSnackbarOpen(true);
        }
    };


    
    let logoutUser = () => {
        setLoading(true);
        setAuthTokens(null)

        setUser(null)
        localStorage.removeItem('authTokens')

        navigate('/login');
        setLoading(false);

    }


    


    


    let contextData = {
        user: user,
        authTokens: authTokens,
        loginUser: loginUser,
        logoutUser: logoutUser,
        fetchStatus: fetchStatus,
        unreadCount: unreadCount,
        setUnreadCount: setUnreadCount
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };

    return (
        <AuthContext.Provider value={contextData}>
   {loading ? <LoadingSpinner  /> : children}
            {/* Snackbar to show success or error message */}
            <Snackbar
  open={snackbarOpen}
  autoHideDuration={6000} // 6 seconds
  onClose={handleClose}
  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Bottom center position
>
  <Alert
    onClose={handleClose}
    severity={snackbarSeverity} // Can be "success", "info", "warning", "error"
    sx={{
      width: '100%', // Full width
      alignItems: 'center',
    }}
    action={
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    }
  >
    {snackbarMessage}
  </Alert>
</Snackbar>
        </AuthContext.Provider>
    );
};


export const useAuth = () => React.useContext(AuthContext);

export { AuthContext, AuthProvider };













