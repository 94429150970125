import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import './Navbar.css';
import ditlogo from '../assets/DIT-logo.png';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileHamOpen, setIsMobileOpen] = useState(false);
  const [accordionOpen, setAccordionOpen] = useState(false);
 
 
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  
  return (
    <>
      <div className="top-nav">
        {/* Top navigation links */}
        {/* <img src={dsadit} alt="SAB Logo" className="logo-top-nav" /> */}
        <div className="nav-links">

          <a className='about-savb' href="/">About DIT</a>
          <a href="/personal">Personal</a>
          {/* <a href="/business">Business</a> */}
        </div>
        <div className="search-container">
          <input type="search" placeholder="Search" />
          <button className="search-btn">🔍</button>
        </div>
   
        <div className="auth-links">
          <a className='log-sab' href="/login">Log in</a>
        </div>
      </div>
      
      <div className='mobile-lang-switch'>
      </div>
      <div className="grey-thin-underline">
        
      </div>

 <div className="main-nav">
 <img src={ditlogo} alt="SAB Logo" className="logo" />

 
 <button className={`hamburger ${isMobileMenuOpen ? "is-active" : ""}`} 
                type="button" 
                onClick={toggleMobileMenu}>
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
        </button>

    <div className="main-nav-links">
      <img src={ditlogo} alt="SAB Logo" className="logo2" />
      <a href="/" data-label="Who We Are">About DIT</a>
      <a href="/firms" data-label="Reports, Accounts, and More">Firms</a>
      <a href="/markets" data-label="Environmental Social & Governance">Markets</a>
      <a href="/consumers" data-label="Human Resources">Consumers</a>
      <a href="/security" data-label="Online Security">Security</a>
      <a href="/newsroom" data-label="2010-2023">Newsroom</a>
    </div>
  </div>



  
  <Accordion expanded={isMobileMenuOpen} onChange={toggleMobileMenu} className="mobile-accordion">
    
  <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
    {/* Intentionally left blank */}
  </AccordionSummary>
  <AccordionDetails>
  <ul className="menu-submenu">
  <div className="red-underline"></div>

    <li><a href="/" data-label="Who We Are">About DIT</a></li>
    <li><a href="/firms" data-label="Reports, Accounts, and More">Firms</a></li>
    <li><a href="/markets" data-label="Environmental Social & Governance">Markets</a></li>
    <li><a href="/consumers" data-label="Human Resources">Consumers</a></li>
    <li><a href="/security" data-label="Online Security">Security</a></li>
    <li><a href="/newsroom" data-label="2010-2023">Newsroom</a></li>
    {/* More links */}
  </ul>
</AccordionDetails>
</Accordion>

 



    
  <div className="red-underline"></div>
</>
  );
};

export default Navbar;
