import React, { useContext, useState, useEffect } from 'react';
import './SideNav.css';
import logo from '../../assets/DIT-logo.png';
import HomeIcon from '@mui/icons-material/Home';
import GradingIcon from '@mui/icons-material/Grading';
import MailIcon from '@mui/icons-material/Mail';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Badge from '@mui/material/Badge';
import { useNavigate } from 'react-router-dom';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import dsalogo from '../../assets/dsa-logo.png';
import { AuthContext } from '../../Context/AuthContext';
const SideNav = ({ onActiveItemChange }) => {
    const {fetchStatus, authTokens, logoutUser, unreadCount  } = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);
    const [activeItem, setActiveItem] = useState('Dashboard');
    const navigate = useNavigate();




    const toggleNav = () => {
        setIsOpen(!isOpen);
    };

    const handleLogout = () => {
        logoutUser();
        navigate('/login');
    };

    const handleItemClick = (item) => {
        setActiveItem(item);
        onActiveItemChange(item);
        if (isOpen) {
            toggleNav();
            window.scrollTo(0, 0);
        }
        fetchStatus(authTokens);
        // Add a fetchStatus call here if needed to update the status and unread count
    };

    

    const renderInboxIcon = () => {
        return unreadCount > 0 ? (
            <Badge
                badgeContent={unreadCount}
                color="error"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{
                    '.MuiBadge-badge': {
                        top: '-10%', 
                        right: '50%', // Adjust these values as needed to move the badge
                        transform: 'translate(50%, -50%)',
                    }
                }}
            >
                <MailIcon />
            </Badge>
        ) : (
            <MailIcon />
        );
    };
    return (
        <>
 <div className="top-bar">
        <div className="hamburger-menu" onClick={toggleNav}>
            <div className="hamburger-box">
                <div className={`hamburger-inner ${isOpen ? 'is-active' : ''}`}></div>
            </div>
        </div>
        <img src={dsalogo} alt="DSA Logo" className="mobile-logo" />
    </div>
            <div className={`side-nav-account ${isOpen ? 'open' : ''}`}>
                <div className="logo-container">
                    <img src={logo} alt="DIT Logo" className="sidebar-logo" />
                </div>
                <div className="nav-items-container">
                    <div className={`side-nav-item ${activeItem === 'Dashboard' ? 'active' : ''}`} onClick={() => handleItemClick('Dashboard')}>
                        <HomeIcon />
                        Dashboard
                    </div>
                    <div className={`side-nav-item ${activeItem === 'Transactions' ? 'active' : ''}`} onClick={() => handleItemClick('Transactions')}>
                        <GradingIcon />
                        Transactions
                    </div>
                    <div className={`side-nav-item ${activeItem === 'Inbox' ? 'active' : ''}`} onClick={() => handleItemClick('Inbox')}>
    {unreadCount > 0 ? (
        <Badge
            badgeContent={unreadCount}
            color="warning"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '.MuiBadge-badge': {
                    top: '-10%', 
                    right: '50%', // Adjust these values as needed to move the badge
                    transform: 'translate(50%, -50%)',
                }
            }}
        >
            <MailIcon />
        </Badge>
    ) : (
        <MailIcon />
    )}
    Inbox
</div>
                <div className={`side-nav-item ${activeItem === 'Profile' ? 'active' : ''}`} onClick={() => handleItemClick('Profile')}>
                    <AccountBoxIcon />
                    Profile
                </div>
            </div>
            <div className="logout-container">
                <div onClick={handleLogout} className="side-nav-item logout-item">
                    <ExitToAppIcon />
                    Logout
                </div>
            </div>
        </div>
    </>
    );
};

export default SideNav;
