import React from 'react';
import CompetitionImage from '../../../assets/firms2.jpg';
import './Cum.css'; // Ensure this CSS file exists and is styled correctly
import GoBackButton from '../../../utils/Goback';
const CompetitionComponent = () => {
    return (
        <div className="competition-container">
                        <GoBackButton/>

            <div className="competition-section" style={{ backgroundImage: `url(${CompetitionImage})` }}></div>
            <div className="competition-content">
                <h1>Promoting Competition</h1>
                <div className="title-bar"></div>
                <p>
                    Our institution stands as a bulwark against the forces that undermine fair competition in the marketplace. With a vigilant eye on market dynamics, we strive to create a landscape where consumers and firms thrive on the principles of equity, choice, and innovation. 
                </p>
                <hr />
                <h2>Enforcing Fair Play</h2>
                <p>
                    Our regulatory actions are driven by a commitment to economic justice. We actively work to identify and dismantle any anti-competitive practices, ensuring that no entity can unfairly tip the scales in its favor. Our rigorous investigative processes and enforcement mechanisms are designed to penalize non-compliance and discourage future violations.
                </p>
                <hr />
                <h2>Stimulating Market Dynamism</h2>
                <p>
                    In the spirit of entrepreneurial growth, we support market entrants and small businesses in navigating the regulatory landscape. Our initiatives seek to lower the barriers to entry and foster an arena where diverse business models can flourish. By offering guidance and advocacy, we aim to stimulate a vibrant ecosystem ripe for economic progress.
                </p>
                <hr />
                <h2>Encouraging Innovation</h2>
                <p>
                    We believe innovation is the lifeblood of competitive markets. Through our policies and incentives, we aim to catalyze advancements in services and technologies that will define the future of finance. Our support extends to research and development endeavors that promise to revolutionize consumer experiences and financial efficiency.
                </p>
                <hr />
                <h2>Ensuring Market Integrity</h2>
                <p>
                    The integrity of financial markets is paramount in preserving consumer trust and stability. We operate with a mandate to foster a transparent and accountable financial environment, where rules are clear, and malpractice faces stringent consequences. Our continuous monitoring and supervision uphold the market's credibility, ensuring that it functions as a fair and orderly system for all participants.
                </p>
                <hr />
                <div className="button-container2">
                    <a href="/competition" className="about-button">Learn More About Our Approach</a>
                </div>
            </div>
        </div>
    );
}

export default CompetitionComponent;
