import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Nav/Navbar';
import HomeComp from './Components/Home/Home';
import PersonalComp from './Components/Personal/Personal';
import FooterComp from './Components/Footer/Footer';
import NewsComp from './Components/Newsroom/News';
import NewsTemp from './Components/Newsroom/NewsTemp';
import FirmsComponent from './Components/Firms/Firms';
import MarketsComp from './Components/Markets/Markets';
import ConsumersComp from './Components/Consumers/Consumers';
import SecurityComp from './Components/Security/Security';
import ProtectComponent from './Components/Home/ExtraHome/Protect/ProtectCon';

import AccountComp from './Components/Dashboard/Account';

import LoginComp from './Components/Login/Login/LoginPage';
import RegisterComponer from './Components/Login/Register/RegisterPage';
import RegisterPart1 from './Components/Login/Register/Signup/SignUp1/RegisterPart1';
import StrategyPlanComponent from './Components/Home/ExtraHome/Plan/Plan';
import CompetitionComponent from './Components/Home/ExtraHome/Competition/Cum';
import MarketIntegrityComponent from './Components/Home/ExtraHome/Intergrity/Int';
import AnnualReportComponent from './Components/Home/ExtraHome/AnnualReport/Report';
import { AuthProvider } from './Components/Context/AuthContext';
import { PrivateRoute } from './Components/utils/PrivateRoute'; // Adjust the path as per your file structure
import { MainLayout } from './Components/utils/MainLayout';
import { AppProvider } from './Components/Context/AppContext';

import SimpleRegister from './Components/Login/Register/Signup/Signup2/Register3';
// import { AppProvider } from './Components/Context/AppContext';

function App() {
  return (
    <Router>
    <AuthProvider>
        <Routes>
            {/* Routes with MainLayout */}
            <Route path="/" element={<MainLayout><HomeComp /></MainLayout>} />
            <Route path="/personal" element={<MainLayout><PersonalComp /></MainLayout>} />
            {/* ... other routes with MainLayout */}
            <Route path="/newsroom" element={<MainLayout><NewsComp /></MainLayout>} />
            <Route path="/news/:id" element={<MainLayout><NewsTemp /></MainLayout>} />
            <Route path="/firms" element={<MainLayout><FirmsComponent /></MainLayout>} />
            <Route path="/markets" element={<MainLayout><MarketsComp /></MainLayout>} />
            <Route path="/consumers" element={<MainLayout><ConsumersComp /></MainLayout>} />
            <Route path="/security" element={<MainLayout><SecurityComp /></MainLayout>} />
            <Route path="/protect" element={<MainLayout><ProtectComponent /></MainLayout>} />
            <Route path="/strategy" element={<MainLayout><StrategyPlanComponent /></MainLayout>} />
            <Route path="/annual-report" element={<MainLayout><AnnualReportComponent /></MainLayout>} />
            <Route path="/competition" element={<MainLayout><CompetitionComponent /></MainLayout>} />
            <Route path="/market-integrity" element={<MainLayout><MarketIntegrityComponent /></MainLayout>} />

            {/* Route without MainLayout */}
            <Route path="/login" element={<MainLayout><LoginComp /></MainLayout>} />
                <Route path="/register" element={<MainLayout><RegisterComponer /></MainLayout>} />
                <Route path="/select" element={<MainLayout><SimpleRegister /></MainLayout>} />

            <Route path="/account" element={
                <PrivateRoute>
                    <AccountComp />
                </PrivateRoute>
            } />
        </Routes>
    </AuthProvider>
</Router>

  );
}

export default App;
