import React, { useState, useContext } from 'react';
import { Box, TextField, Button, Typography, Alert, AlertTitle, CircularProgress, Divider, Autocomplete  } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { AuthContext } from '../../../Context/AuthContext';
import './BankDets.css';
import banksList from '../../..//utils/BList.json'
const BankDetailsComp = () => {
  const { fetchStatus } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [selectedBank, setSelectedBank] = useState(null);

  const [bankNameInput, setBankNameInput] = useState('');

  const handleBankChange = (event, newValue, reason) => {
    if (reason === 'select-option' && newValue) {
      setBankNameInput(newValue.name); // Set the bank name from selection
    } else if (reason === 'clear' || reason === 'remove-option') {
      setBankNameInput(''); // Clear the bank name
    }
  };

  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    iban: '',
    swiftBic: '',
    accountHolderName: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('');

  // Regex patterns for IBAN and SWIFT/BIC
  const ibanPattern = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{11,29}$/;
  const swiftBicPattern = /^[A-Z]{6}[A-Z2-9][A-NP-Z0-9]([A-Z0-9]{3})?$/;

  const validate = () => {
    let tempErrors = {};
    tempErrors.bankName = bankNameInput.trim() ? '' : 'Bank name is required.';
    tempErrors.iban = ibanPattern.test(bankDetails.iban) ? '' : 'Please enter a valid IBAN.';
    tempErrors.swiftBic = swiftBicPattern.test(bankDetails.swiftBic) ? '' : 'Please enter a valid SWIFT/BIC.';
    tempErrors.accountHolderName = bankDetails.accountHolderName ? '' : 'Account holder name is required.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === '');
  };
  const handleInputChange = (event, newInputValue) => {
    setBankNameInput(newInputValue);
  };
  // Field-specific validation logic
  const validateField = (name, value) => {
    switch (name) {
      case 'iban':
        return ibanPattern.test(value) ? '' : 'Please enter a valid IBAN.';
      case 'swiftBic':
        return swiftBicPattern.test(value) ? '' : 'Please enter a valid SWIFT/BIC.';
      default:
        return value ? '' : 'This field is required.';
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }
    setIsSubmitting(true);

    const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';
    const submissionData = {
      ...bankDetails,
      bankName: bankNameInput, // This ensures we use the current input
    };

    try {
      const response = await fetch(`${apiUrl}/update_bank_details/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
               body: JSON.stringify(submissionData),
 // Make sure this is using the correct value
          // ... other bank details
       
      });

      if (!response.ok) {
        throw new Error('Failed to submit bank details');
      }

      setSubmissionStatus('success');
      window.scrollTo(0, 0);

      await fetchStatus({ access: authToken });
    } catch (error) {
      console.error('Error submitting bank details:', error);
      setSubmissionStatus('error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleAutocompleteInputChange = (event, newInputValue) => {
    // Just update the input value state
    setBankNameInput(newInputValue);
  };
  const handleFormInputChange = (event) => {
    const { name, value } = event.target;
    setBankDetails({ ...bankDetails, [name]: value });
    // Additional validation logic if needed
  };

  return (
    <Box elevation={3}>
      {submissionStatus !== 'success' ? (
        <>
          <Typography variant="h5" gutterBottom>
            <AccountBalanceIcon sx={{ mr: 1, verticalAlign: 'bottom' }} />
            Banking Details
          </Typography>
          <Alert severity="info" sx={{ mb: 3 }}>
            <AlertTitle>Enter Your Banking Information</AlertTitle>
            Your banking details are required to initiate and process transactions securely.
          </Alert>
          <Typography variant="body1" sx={{ mb: 2 }}>
            {/* Informative text */}
          </Typography>
          <Divider sx={{ my: 3 }} />
          <Box component="form" className="form-container" onSubmit={handleSubmit} noValidate autoComplete="off" sx={{ '& .MuiTextField-root': { mb: 2 }, '& .MuiButton-root': { mt: 1 } }}>
          <Autocomplete
  freeSolo
  value={selectedBank}
  onChange={handleBankChange}
  onInputChange={handleAutocompleteInputChange}
  inputValue={bankNameInput}
  options={banksList || []}
  getOptionLabel={(option) => option?.name || ''}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Bank Name"
      variant="filled"
      error={!!errors.bankName}
      helperText={errors.bankName || "Select or type your bank name."}
      required
    />
  )}
/>

            <TextField
              label="IBAN"
              variant="filled"
              name="iban"
              value={bankDetails.iban}
              onChange={handleFormInputChange}
              required
              fullWidth
              helperText={errors.iban || "Your International Bank Account Number."}
              error={!!errors.iban}
            />
            <TextField
              label="SWIFT/BIC Code"
              variant="filled"
              name="swiftBic"
              value={bankDetails.swiftBic}
              onChange={handleFormInputChange}
              required
              fullWidth
              helperText={errors.swiftBic || "The SWIFT code for your bank."}
              error={!!errors.swiftBic}
            />
            <TextField
              label="Account Holder's Name"
              variant="filled"
              name="accountHolderName"
              value={bankDetails.accountHolderName}
              onChange={handleFormInputChange}
              required
              fullWidth
              helperText={errors.accountHolderName || "The name registered with your bank account."}
              error={!!errors.accountHolderName}
            />
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              fullWidth
              size="large"
              sx={{ py: 1.5 }}
            >
              {isSubmitting ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
            <Divider sx={{ my: 3 }} />

          </Box>
        </>
      ) : (
        <Alert severity="success" sx={{ my: 2 }}>
          <AlertTitle>Banking Details Submitted</AlertTitle>
          Your banking details have been successfully submitted and are being verified. You can update your details anytime through your account settings.
        </Alert>
      )}

      {submissionStatus && submissionStatus !== 'success' && (
        <Alert severity="error" sx={{ mt: 3 }}>
          Failed to submit details. Please try again or contact support if the issue persists.
        </Alert>
      )}
    </Box>
  );
};

export default BankDetailsComp;

