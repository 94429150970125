import React, { useContext, useState, useEffect } from 'react';
import {
  Paper,
  Typography,
  Button,
  Divider,
  Grid,
  Skeleton,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AuthContext } from '../../Context/AuthContext';

const ProfileMainComp = () => {
  const { user } = useContext(AuthContext);
  const [bankDetails, setBankDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const authToken = localStorage.getItem('authTokens')
    ? JSON.parse(localStorage.getItem('authTokens')).access
    : null;
    const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    setIsLoading(true);
    fetch(`${apiUrl}/banking-details/`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setBankDetails(data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching bank details:', error);
      })
      .finally(() => {
      });
  }, [authToken]);

  const renderDetail = (content) =>
    isLoading ? <Skeleton variant="text" width="80%" /> : content;

  return (
    <div>
      <Paper elevation={3} sx={{ mb: 2, p: 2 }} className="profile-main-paper">

      <Grid container justifyContent="space-between" spacing={2}>
  <Grid item xs={12}>
    <Typography variant="h6" component="div" className="profile-title">
      <AccountCircleIcon className="profile-icon" />
      Profile
    </Typography>
    <Divider light className="profile-divider" />
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography variant="body1" fontWeight="bold">Name:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(user?.first_name)}
    </Typography>
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography variant="body1" fontWeight="bold">Surname:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(user?.last_name  || 'N/A')}
    </Typography>
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography variant="body1" fontWeight="bold">Email:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(user?.email)}
    </Typography>
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography variant="body1" fontWeight="bold">Swift Code:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(bankDetails?.swift_code  || 'N/A')}
    </Typography>
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography variant="body1" fontWeight="bold">Bank Name:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(bankDetails?.bank_name  || 'N/A')}
    </Typography>
  </Grid>

  <Grid item xs={12} sm={6}>
    <Typography variant="body1" fontWeight="bold">Account Number:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(bankDetails?.account_number  || 'N/A')}
    </Typography>
  </Grid>

  <Grid item xs={12}>
    <Typography variant="body1" fontWeight="bold">IBAN:</Typography>
    <Typography variant="body2" color="textSecondary">
      {renderDetail(bankDetails?.iban  || 'N/A')}
    </Typography>
  </Grid>
</Grid>
        </Paper>
            <Grid container spacing={2} className="profile-actions">
               
                <Grid item xs={12} sm={4}>
                    {/* <Button variant="contained" color="primary" className="profile-action-button">
                        Update Withdrawal Information
                    </Button> */}
                </Grid>
            </Grid>
        </div>
    );
}

export default ProfileMainComp;
