// ConsumersComp.js
import React from 'react';
import ConsumerImg from '../assets/consumer.jpg';

import './Consumers.css';

const ConsumersComp = () => {
    return (
        <div className="consumers-container">
            <div className="consumers-section" style={{ backgroundImage: `url(${ConsumerImg})` }}></div>
            <div className="consumers-content">
                <h1>Consumer Protection</h1>
                <div className="title-bar"></div>

                <p className="consumers-meta">
                    <span>First published: 08/05/2015</span> | 
                    <span>Last updated: 13/07/2023</span> | 
                    <a href="/updates">See all updates</a>
                </p>
                <hr/>
                <p>Welcome to our comprehensive guide on Consumer Protection. Here, you'll find everything you need to know about your rights as a consumer, how to navigate the market safely, and what to do if you encounter issues.</p>
                
                <h2>Understanding Your Consumer Rights</h2>
                <p>Consumer rights are the legal rights afforded to you as a consumer. These include the right to safety, the right to be informed, the right to choose, and the right to be heard. Understanding these rights is the first step in ensuring you are treated fairly.</p>
                
                <h2>Key Consumer Protection Laws</h2>
                <p>Various laws protect consumers from unfair practices. These include the Consumer Protection Act, Fair Credit Reporting Act, Fair Debt Collection Practices Act, and more. Familiarizing yourself with these laws can help you understand your protections.</p>
                
                <h2>Tips for Smart Consumers</h2>
                <ul>
                    <li>Always read product information carefully before purchasing.</li>
                    <li>Keep receipts and documentation for all major purchases.</li>
                    <li>Be aware of common scams and fraudulent activities.</li>
                    <li>Know your rights when it comes to returns and warranties.</li>
                </ul>
                
                <h2>Filing Complaints and Getting Assistance</h2>
                <p>If you have a problem with a product or service, you have the right to complain. Start by contacting the business directly. If that doesn't resolve the issue, you can file a complaint with consumer protection agencies.</p>
                
                <p>If you have any concerns or need assistance, please <a href="/contact">contact our consumer support team</a>. We're here to help you navigate the marketplace safely and confidently.</p>
                
                <h2>Useful Resources</h2>
                <p>For more information, visit the following resources:</p>
                <ul>
                    <li><a href="https://www.consumerprotection.gov">Consumer Protection Agency</a></li>
                    <li><a href="https://www.ftc.gov">Federal Trade Commission</a></li>
                    <li><a href="https://www.consumerfinance.gov">Consumer Financial Protection Bureau</a></li>
                </ul>
            </div>
        </div>
    );
}

export default ConsumersComp;
