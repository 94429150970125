import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Container } from '@mui/material';

const SimpleRegister = () => {
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
    });

    const handleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${apiUrl}/registerttt/`, userData, {
                headers: { 'Content-Type': 'application/json' },
            });
            console.log('Registration successful:', response.data);
            // Reset form or redirect user
        } catch (error) {
            console.error('Registration failed:', error.response?.data || error.message);
        }
    };

    return (
        <Container maxWidth="xs">
            <form onSubmit={handleSubmit}>
                <TextField label="Username" name="username" fullWidth margin="normal" onChange={handleChange} />
                <TextField label="Email" name="email" fullWidth margin="normal" onChange={handleChange} />
                <TextField label="Password" name="password" type="password" fullWidth margin="normal" onChange={handleChange} />
                <TextField label="First Name" name="first_name" fullWidth margin="normal" onChange={handleChange} />
                <TextField label="Last Name" name="last_name" fullWidth margin="normal" onChange={handleChange} />
                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }}>Register</Button>
            </form>
        </Container>
    );
};

export default SimpleRegister;
