import React from 'react';
import './Footer.css'; // Ensure this path is correct
import logo from '../assets/dsa-logo2.png'; // Update the path as needed

const FooterComp = () => {
    const currentYear = new Date().getFullYear(); // This ensures the year updates dynamically

    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo">
                    <img src={logo} alt="DIT Logo" />
                </div>
                <div className="footer-content">
                    <p>© {currentYear} Department of International Trade and Trust (DIT). All rights reserved.</p>
                    <p>Dedicated to fostering digital integrity and safeguarding consumers in the digital age.</p>
                    <div className="footer-links">
                        <p href="/privacy-policy" aria-label="Privacy Policy">Privacy Policy</p>
                        <p href="/terms-of-service" aria-label="Terms of Service">Terms of Service</p>
                        <p href="/accessibility" aria-label="Accessibility Statement">Accessibility</p>
            <div className="footer-bottom2">
            ISO 27001 Certified for Information Security Management

            </div>
                    <p>+44 2037 726 920</p>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© {currentYear} DIT, All Rights Reserved</p>
                <p>Championing equitable and secure digital commerce worldwide.</p>
            </div>
        </footer>
    );
}

export default FooterComp;
