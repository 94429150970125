import React from 'react';
import { Container, Paper, Typography, Box, Button, Divider, Grid } from '@mui/material';
import AnnualReportImg from '../../../assets/firms2.jpg'; // Ensure this is the correct path
import GoBackButton from '../../../utils/Goback';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const getCurrentYearSpan = () => {
    const currentYear = new Date().getFullYear();
    const previousYear = currentYear - 1;
    return `${previousYear}/${currentYear.toString().slice(2)}`;
  };

  const reportYearSpan = getCurrentYearSpan();


const AnnualReportComponent = () => {
  return (
    <Container maxWidth="lg">
      <Box my={4} component="section" sx={{ position: 'relative' }}>
        <GoBackButton />

        <Item elevation={4} sx={{ backgroundImage: `url(${AnnualReportImg})`, backgroundSize: 'cover', color: '#fff', mb: 4 }}>
          <Typography variant="h3" component="h1" gutterBottom>
          <h1>Annual Report {reportYearSpan}</h1>
          </Typography>
        </Item>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Item>
              <Typography variant="h4" gutterBottom>
                Executive Summary
              </Typography>
              <Divider />
              <Typography paragraph>
                Reflecting on a year of challenges and triumphs, our commitment to financial integrity and consumer advocacy has driven our success. This report showcases our achievements and ongoing dedication to excellence.
              </Typography>
            </Item>
          </Grid>

          <Grid item xs={12} md={6}>
            <Item>
              <Typography variant="h4" gutterBottom>
                Fiscal Stewardship
              </Typography>
              <Divider />
              <Typography paragraph>
                Through prudent financial management, we've navigated economic uncertainties, achieving steady growth and strengthening our market presence.
              </Typography>
            </Item>
          </Grid>

          {/* Continue the pattern for other sections */}

        </Grid>

        <Box textAlign="center" mt={5}>
  <div className="annual-report-content">
  <h1>Annual Report {reportYearSpan}</h1>
    <div className="title-bar"></div>
    
    <h2>Executive Summary</h2>
    <p>
      This year, our journey through formidable challenges has been marked by resilience and innovation. Our dedication to upholding financial integrity and championing consumer rights has been the bedrock of our operations. This report unfolds our relentless pursuit of excellence, showcasing our commitment to navigating the complexities of financial fraud with astute guidance and strategic foresight.
    </p>
    <hr />

    <h2>Fiscal acumen</h2>
    <p>
      Our fiscal acumen, reinforced by vigilant oversight and strategic investments in cutting-edge security measures, has fortified our platform’s capability to offer unwavering support to those affected by financial misdemeanors. The robustness of our financial health has enabled us to sustain and amplify our efforts towards securing personal and financial data against unauthorized use.
    </p>
    <hr />
    
    <h2>Consumer Empowerment</h2>
    <p>
      Advocating for consumer rights and bolstering confidence through proactive support have been paramount in our endeavors. This year’s initiatives have significantly enhanced consumer autonomy, embedding fairness and transparency not just as core tenets but as practiced standards within our operations.
    </p>
    <hr />
    
    <h2>Strategic Outlook</h2>
    <p>
      Looking forward, our strategy is aimed at not only refining our core capabilities but also extending our reach to enlighten and protect against financial fraud. Through education, robust community engagement, and strategic alliances with leading cybersecurity entities, we are set to fortify the digital landscape against financial threats.
    </p>
    <hr />

    <h2>Commitment to Community and Integrity</h2>
    <p>
      Our commitment transcends market performance, touching the lives within the communities we serve. Through sustained outreach and philanthropy, we strive to address societal challenges, promoting socioeconomic advancement and championing the cause of sustainable development.
    </p>
    <hr />

    <h2>Regulatory Insight and Compliance</h2>
    <p>
      As regulatory landscapes evolve, we remain at the forefront of policy adaptation and compliance, ensuring our operations not only adhere to current regulations but also advocate for the enactment of policies that bolster consumer protection and empower our stakeholders.
    </p>
  </div>
</Box>
      </Box>
    </Container>
  );
}

export default AnnualReportComponent;
