import React from 'react';
import './About.css'; // Make sure this path is correct
import aboutImg from '../../../assets/about.jpg'

import grow from '../../../assets/plant.png'
import shield from '../../../assets/secure-shield.png'

import charticon from '../../../assets/bar-chart.png'
const AboutComp = () => {

    const getCurrentYearSpan = () => {
        const currentYear = new Date().getFullYear();
        const previousYear = currentYear - 1;
        return `${previousYear}/${currentYear.toString().slice(2)}`;
      };
      const reportYearSpan = getCurrentYearSpan();

    return (
        <div className="about-container">
            <h1>About DIT</h1>
            <div className="title-bar"></div>

            <p>
                DIT is devoted to safeguarding consumers, enhancing the integrity of the marketplace, 
                and fostering competitive practices for the benefit of consumers. We're at the forefront 
                of shaping a robust and ethical financial system where fairness prevails and consumers are 
                accorded their rightful protections.
            </p>
            <p>
                Our mission extends to buttressing a vibrant financial system that upholds consumer interests, 
                ensuring a fair and equitable exchange for all participants. Our actions are designed to maintain 
                and enhance the health and success of the financial environment.
            </p>
            <div className="objectives-container">
                <div className="objective-card">
                <div className="objective-icon">
                            <img src={shield} alt="Chart Icon" />
                        </div>                  
                        <div className="objective-title">
                            <h3>Protecting consumers</h3>
                            <div className="title-bar"></div>
                    </div>

                        <p>We protect consumers from the harm caused by bad conduct in financial services.</p>
                        <a href="/protect" className="objective-link">
                            <button className="objective-button">Find out how</button>
                        </a>               
                    </div>
                        <div className="objective-card">
                        <div className="objective-icon">
                            <img src={charticon} alt="Chart Icon" />
                        </div>                   
                <div>

                    <h3>Enhancing market integrity</h3>
                    <div className="title-bar"></div>
                    </div>
                    <p>We aim to support a healthy and successful financial system.</p>
                    <a href="/market-integrity" className="objective-link">

                    <button className="objective-button">Learn more</button>
                    </a>
                </div>
                <div className="objective-card">
                <div className="objective-icon">
                            <img src={grow} alt="Chart Icon" />
                        </div>                        <div>

                    <h3  href="/competition" >Promoting competition</h3>
                    <div className="title-bar"></div>
                    </div>

                    <p>We promote effective competition in the interests of consumers and take action to address concerns.</p>
                    <a href="/competition" className="objective-link">
                    <button className="objective-button">What we do</button>
                    </a>
                </div>
            </div>
            <p>
                We adopt an assertive and innovative approach to averting harm, establishing superior standards, 
                and instigating beneficial transformations within financial services.
            </p>
            <div className="how-we-work-section">
  <h2>How we work</h2>
  <div className="title-bar"></div>
  <div className="content-flex-container"> {/* Flex container added here */}
    <div className="text-container">
      <p>
        Our expectations for financial services over the next three years, how we'll measure our performance,
        and the progress we've made against our targets.
      </p>
    </div>
    <img src={aboutImg} alt="About DIT" className="about-image" />
  </div>
  <div className="button-container2">
    <a href="/strategy" className="about-button">Our Strategy and Business Plan &rarr;</a>
    <a href="/annual-report" className="about-button">Our Annual Report {reportYearSpan} &rarr;</a>
  </div>
</div>
            <p>
                For instance, our efforts with Buy Now Pay Later providers have ensured that consumers receive 
                clear and concise information, enabling them to make informed financial decisions.
            </p>
            <p>
                Our Early and High Growth Oversight initiative aids newly authorized firms in navigating our 
                regulatory framework, upholding the high standards we set for the financial community.
            </p>
          
            <p>
                Additionally, we are proactive in identifying and addressing misleading practices among credit 
                brokers, protecting consumers from the risks associated with high-cost short-term credit.
            </p>
            <p>
                Explore our live consultations or peruse our comprehensive collection of publications, 
                final rules, and other informative materials.
            </p>
            <p>
                DIT's diverse team encompasses professionals from law, insurance, banking, policy, 
                technology services, and more. We're united in our goal to deliver financial integrity 
                and consumer protection.
            </p>
            {/* <button className="about-button">Our Vision</button> Add this line for the button */}

        </div>
    );
}

export default AboutComp;
