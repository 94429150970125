import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const AccountRecoveryConfirmComp = () => {
  return (
    <Box sx={{ Width: '60%'}}>
      <Alert
        severity="success"
        icon={<CheckCircleOutlineIcon fontSize="inherit" />}
        sx={{ mb: 2 }}
      >
        <AlertTitle>Account Recovery Complete</AlertTitle>
        The detailed scrutiny of your account registration has concluded successfully.
      </Alert>

      <Divider sx={{ my: 3 }} />
      <Typography variant="body1" sx={{ mb: 2 }}> {/* Added bottom margin */}
        A comprehensive and secure process involving legal and forensic examination has verified the accounts listed below as yours. Each account has been meticulously authenticated and is now ready for your management. Our collaborative efforts with regulatory authorities ensure the recovery and display of your financial data align with stringent compliance standards. 
      </Typography>
      <Typography variant="body1">
        For the protection of your assets and personal information, please review the displayed accounts and initiate any required actions through the designated legal channels. This proactive measure is pivotal for maintaining the integrity of your financial engagements and securing your rights.
      </Typography>
      <Divider sx={{ my: 3 }} />
    </Box>
  );
};

export default AccountRecoveryConfirmComp;
