import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, Divider, TableHead, TableRow, Paper,
  Typography, Box, Button, IconButton, Skeleton
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DownloadIcon from '@mui/icons-material/Download';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';


const UserAccounts = () => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pdfDocumentUrl, setPdfDocumentUrl] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${apiUrl}/user/accounts/`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setAccounts(data);

        // Assuming every account has the same `pdf_document_url`, use the first one to check.
        // This is not ideal; backend should provide user-level details separately.
        const pdfUrl = data.length > 0 ? data[0].pdf_document_url : '';
        setPdfDocumentUrl(pdfUrl);
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      } finally {
        setIsLoading(false);

      }
    };


    fetchAccounts();
  }, [authToken]);

  const renderSkeletonRows = (numRows, numCols) => {
    return Array.from({ length: numRows }).map((_, rowIndex) => (
      <TableRow key={`skeleton-row-${rowIndex}`}>
        {Array.from({ length: numCols }).map((_, colIndex) => (
          <TableCell key={`skeleton-col-${colIndex}`}>
            <Skeleton variant="text" width="100%" />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US', { style: 'decimal' }).format(num);
  };

  const formatStatusText = (status) => {
    switch (status) {
      case 'WITHDRAWN':
        return 'Withdrawn';
      case 'ACTIVE':
        return 'Active';
      default:
        return status;
    }
  };

  const downloadDocuments = () => {
    // Trigger download of the document
    window.open(pdfDocumentUrl, '_blank');
  };

if (isLoading) {
    return (
      <Box>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
            <TableRow>
        <TableCell><Skeleton variant="text" width="40%" /></TableCell>
        <TableCell align="right"><Skeleton variant="text" width="60%" /></TableCell>
        <TableCell align="right"><Skeleton variant="text" width="50%" /></TableCell>
        <TableCell align="right"><Skeleton variant="text" width="80%" /></TableCell>
      </TableRow>
            </TableHead>
            <TableBody>
              {renderSkeletonRows(5, 4)} {/* Assuming 5 rows and 4 columns */}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="body2" align="center" sx={{ mt: 2 }}>
          Loading...
        </Typography>
      </Box>
    );
  }


  if (!accounts.length) {
    return <Typography></Typography>;
  }

  return (
<Box >
  


  <TableContainer component={Paper}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell>Account #</TableCell>
          <TableCell align="right">Date Registered</TableCell>
          <TableCell align="right">Balance (€)</TableCell>
          <TableCell align="right">Account Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {accounts.map((account, index) => (
          <TableRow key={index}>
            <TableCell>#{account.account_number}</TableCell>
            <TableCell align="right">{account.date}</TableCell>
            <TableCell align="right" sx={{ color: account.ACstatus === 'ACTIVE' ? green[500] : red[500] }}>
              {formatNumber(account.amount)}€
            </TableCell>
            <TableCell align="right" sx={{ color: account.ACstatus === 'ACTIVE' ? green[500] : red[500] }}>
              {formatStatusText(account.ACstatus)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  
  <Box sx={{ mt: 2 }}>
  {pdfDocumentUrl && (
    <>
      <Typography variant="body2" sx={{ mb: 2 }}>
        For your convenience, official documents substantiating the ownership and status of the listed accounts are prepared. These documents are confidential and intended solely for the addressee. Download the documented proofs for your records and requisite actions. Should you require further verification, please contact our support team.
      </Typography>
      <Button
        variant="contained"
        startIcon={<DownloadIcon />}
        onClick={downloadDocuments}
        sx={{ mb: 2 }}
      >
        VIEW Document
      </Button>
    </>
  )}
    <Alert
      severity="warning"
      icon={<NotificationImportantIcon fontSize="inherit" />}
      sx={{ mb: 2 }}
    >
      <AlertTitle>Confidentiality Notice</AlertTitle>
      The accounts listed herein have been securely recovered and authenticated as belonging to you. We prioritize the security of your financial information and comply with data protection regulations. maintaining the confidentiality and integrity of your account information is paramount.
    </Alert>
  </Box>
  <Divider sx={{ my: 3 }} />

</Box>
  );
};

export default UserAccounts;
