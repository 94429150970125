// NewsTemp.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import newsData from './NewsData.json';
import './NewsTemp.css'
const NewsTemp = () => {
  const { id } = useParams();
  const [newsItem, setNewsItem] = useState(null);

  useEffect(() => {
    console.log('Current ID:', id);
    const item = newsData.find(news => news.id === parseInt(id, 10));
    console.log('Fetched Item:', item);
    setNewsItem(item);
  }, [id]);

  if (!newsItem) {
    return <div>Loading...</div>;
  }

  return (
    <div className="news-temp-container">
    <h1 className="news-temp-title">{newsItem.title}</h1>
    <div className="news-temp-meta">
      <span className="news-temp-date">{newsItem.date}</span>
      <span className="news-temp-divider">|</span>
      <span className="news-temp-category">{newsItem.category}</span>
    </div>
    <div className="news-temp-content">{newsItem.content}</div>
  </div>
  );
};

export default NewsTemp;
