import React from 'react';
import background from '../assets/fam.jpg'; // Ensure the path is correct
import './Personal.css'

import 'slick-carousel/slick/slick.css'; // Default styling
import 'slick-carousel/slick/slick-theme.css'; // Theme styling
const PersonalComp = () => {

  
    return (
        <div className="personal-container">
            <div className="background-section" style={{ backgroundImage: `url(${background})` }}>
            </div>
            <div className="cookie-consent">
                <p>
                    DIT uses cookies to give you the best possible experience on this website. 
                    Please read our <a href="/cookie-policy" className="cookie-policy-link">cookie policy</a>.
                <button className="close-cookie-consent">✕</button>
                </p>
            </div>
            <div className="personal-content">
                <h1>Personalized Resources at DIT</h1>
                <div className="title-bar"></div>

                <p>
                    At Diversified International Trade (DIT), we believe that personal touch in business 
                    creates long-lasting relationships. Our commitment to personal services is at the heart 
                    of our operations, ensuring that each client receives tailored solutions that resonate 
                    with their unique needs and aspirations.
                </p>
                <p>
                    We understand that personal and business success are intertwined. That's why our approach 
                    extends beyond traditional commerce, fostering personal growth and professional development 
                    for our clients. Whether it's through individualized asset recovery strategies or customized 
                    trade solutions, DIT stands as a partner in personal and business prosperity.
                </p>
                <p>
                    Our dedicated team of professionals excels in providing personal service that understands 
                    the intricacies of your individual and business requirements. This dual focus is what 
                    enables us to offer comprehensive solutions that contribute not just to your company's 
                    bottom line, but also to your personal financial health.
                </p>
                <p>
                    Integrity, innovation, and excellence drive us to deliver personal services that exceed 
                    expectations. Join us at DIT, where personal business is not just a phrase—it's our 
                    promise to you.
                </p>
                <button className="personal-button">Explore Our Services</button>
            </div>
        

       </div>
    );
}

export default PersonalComp;
