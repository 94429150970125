import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Success icon

const UploadButton = styled(IconButton)({
    borderRadius: '50%',
    border: '2px dashed gray',
    width: '100px',
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'border 0.3s ease-in-out, transform 0.3s ease-in-out',
    '&:hover': {
      borderColor: 'primary.main',
      backgroundColor: 'rgba(25, 118, 210, 0.04)',
      transform: 'scale(1.05)',
    },
    '& svg': {
      fontSize: '3rem',
      color: 'primary.main',
    },
    '&:hover svg': {
      color: 'secondary.main',
    },
  });

const BankStatementComp = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }

    setIsUploading(true);
    setUploadError('');

    const formData = new FormData();
    formData.append('bank_statement', file); // Match the key with your backend

    try {
      const response = await fetch(`${apiUrl}/upload_bank_statement/`, { // Adjust this URL as necessary
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : ''}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to upload bank statement. Please try again.');
      }

      setUploadSuccess(true);
    } catch (error) {
      console.error('Upload error:', error);
      setUploadError('Failed to upload bank statement. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box>
      {uploadSuccess ? (
        <Alert severity="success" icon={<CheckCircleIcon fontSize="inherit" />} sx={{ mb: 2 }}>
          <AlertTitle>Upload Successful</AlertTitle>
          Your bank statement has been submitted and is under review.
        </Alert>
      ) : (
        <Alert severity="info" icon={<UploadFileIcon fontSize="inherit" />} sx={{ mb: 2 }}>
          <AlertTitle>Bank Statement Submission Required</AlertTitle>
          To verify account ownership and balance, please submit your most recent bank statement.
        </Alert>
      )}

      {!uploadSuccess && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
          <UploadButton color="primary" component="label" disabled={isUploading}>
            {isUploading ? (
              <CircularProgress size={48} color="primary" />
            ) : (
              <UploadFileIcon />
            )}
            <input type="file" hidden onChange={handleFileUpload} accept=".pdf,image/*" disabled={isUploading} />
          </UploadButton>
          <Typography variant="caption" sx={{ mt: 1 }}>
            Click the upload icon to submit your bank statement.
          </Typography>
          {uploadError && <Typography color="error" sx={{ mt: 2 }}>{uploadError}</Typography>}
        </Box>
      )}

      {!uploadSuccess && (
        <Typography variant="body1">
          Upload a clear scan or photo of your bank statement. Ensure all relevant details such as your name, account number, and balance are visible.
        </Typography>
      )}
    </Box>
  );
};

export default BankStatementComp;
