import React, { useState, useContext } from 'react';
import { Container, TextField, Button, Paper, Typography, Alert } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link } from 'react-router-dom'; // Ensure this is imported from 'react-router-dom'
import './LoginPage.css';
import { CircularProgress } from '@mui/material';

import { AuthContext } from '../../Context/AuthContext';

const LoginComp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    const { loginUser } = useContext(AuthContext);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setIsLoggingIn(true); // Start loading
        try {
            const payload = {
                email: email.toLowerCase(),
                password: password,
            };
            await loginUser(payload);
        } catch (error) {
            console.log(error.response?.data || 'An unknown error occurred');
        }
        setIsLoggingIn(false); // Stop loading once the loginUser promise settles
    };
    return (
        <Container maxWidth="sm" className="login-container">
            <Alert icon={<LockOutlinedIcon fontSize="inherit" />} severity="info" className="login-alert">
                Remember: Your security is paramount to us. Keep your login credentials confidential to protect your account against unauthorized access.
            </Alert>
            <Paper elevation={3} className="login-paper">
                <Typography variant="h5" component="h3" className="login-title">
                    Sign In
                </Typography>

                <form className="login-form" onSubmit={handleLoginSubmit}>
                    <TextField
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        className="login-input"
                    />
                    <TextField
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        className="login-input"
                    />
         <Button
    type="submit"
    variant="contained"
    color="primary"
    fullWidth
    className="login-button"
    disabled={isLoggingIn} // Disable the button while logging in
>
    {isLoggingIn ? <CircularProgress size={24} /> : 'Log In'}
</Button>
                </form>

                <Typography variant="body2" className="login-footer-text">
                    Not registered yet? <Link to="/register" className="register-link">Register</Link>
                </Typography>

                <Typography variant="body1" className="login-info-text">
                    Sign in to access your account and manage your services.
                </Typography>
            </Paper>
        </Container>
    );
};

export default LoginComp;
