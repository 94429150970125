import React from 'react';
import { Box, Typography, Alert, AlertTitle, Divider } from '@mui/material';

const ConfirmedBankDets = () => {
    return ( 
        <Box>
            <Alert severity="success" sx={{ my: 2 }}>
                <AlertTitle>Banking Details Verified and Confirmed</AlertTitle>
                Your banking details have been successfully verified and confirmed. We are currently reviewing additional aspects of your case to ensure comprehensive protection and support. This is a critical step in our ongoing efforts to address and rectify the issues arising from fraudulent activities affecting your financial assets. Please be assured that we are working diligently to complete this review. Further updates and actions required on your part, if any, will be communicated promptly through your account's inbox. We appreciate your cooperation and patience during this process.
            </Alert>
        </Box>
    );
}
 
export default ConfirmedBankDets;
