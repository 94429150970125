import React from 'react';
import './Purpose.css'; // Make sure this path is correct

const PurposeComp = () => {
    return (
        <div className="purpose-container">
            <h1>Our Purpose</h1>
            <div className="title-bar"></div>

            <p>
                At Diversified International Trade (DIT), our purpose is to bridge the gap between markets on a global scale, bringing together buyers and sellers from all corners of the world. We aim to facilitate economic growth, support sustainable practices, and drive innovation in international trade.
            </p>
            <p>
                We are committed to providing reliable, efficient, and ethical trade solutions that empower communities, support development, and create value for all stakeholders involved. Our purpose is rooted in the belief that commerce can be a force for positive change, fostering mutual understanding and prosperity across cultures.
            </p>
            <p>
                By focusing on asset recovery, we give a second life to underutilized resources, promoting a circular economy that benefits the environment and the economy alike. Our purpose is not just about profit, but about creating a more connected and sustainable world through responsible trade.
            </p>
        </div>
    );
}

export default PurposeComp;
