import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom'; // If you're using React Router for navigation

const GoBackButton = () => {
    const navigate = useNavigate(); // Hook for navigation

    return (
        <Button
            variant="outlined"
            startIcon={<ArrowBackIcon sx={{ color: '#0056b3' }} />} // Blue arrow icon
            onClick={() => navigate(-1)} // Goes back to the previous page
            sx={{
                margin: 1, // Adjust spacing as needed
                backgroundColor: '#ffffff', // Button background color set to white
                borderColor: '#0056b3', // Blue border color
                '&:hover': {
                    backgroundColor: '#ffffff', // Maintain white background on hover
                    borderColor: '#003c82', // Darker border color on hover
                },
                color: '#0056b3', // Blue text/icon color
                minWidth: 40, // Keep the button square
                height: 40, // Specify height to match the width
                padding: '6px', // Adjust padding to ensure the icon is centered
                boxShadow: 'none', // Remove the shadow effect
            }}
        />
    );
}

export default GoBackButton;
