import React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const AwaitingConfirmComp = () => {
  return (
    <Box sx={{ Width: '60%' }}>
      <Alert
        severity="info"
        icon={<HourglassEmptyIcon fontSize="inherit" />}
        sx={{ mb: 2 }}
      >
        <AlertTitle>Registration Submitted – Awaiting Verification</AlertTitle>
        Your submission has been received and is currently under verification. The verification process is vital to ensure compliance with the requisite security measures and regulations.
      </Alert>
      
      <Typography variant="body1">
        Notification regarding the verification outcome and any further requirements will be communicated promptly through your account's inbox. It is imperative for the efficiency of this process that your contact information remains current and accurate.
      </Typography>
      <Divider sx={{ my: 3 }} />

    </Box>
  );
};

export default AwaitingConfirmComp;
