import React, { useState, useEffect } from 'react';
import {
  Paper, Typography, Box, Button, Divider, Grid, Chip, Tooltip,Skeleton
} from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Alert from '@mui/material/Alert';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import TransactionModal from './TransModal/TransModal';

const TransMainComp = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [bankingDetails, setBankingDetails] = useState(null);

  const [data, setData] = useState({ transactions: [], CAN_int: false });

  const authToken = localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')).access : null;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchTransactions = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/transactions/`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setTransactions(data.transactions);
        setBankingDetails(data.banking_details);
      } catch (error) {
        console.error("Fetching transactions failed: ", error);
      } finally {
        setLoading(false);

      }
    };

    fetchTransactions();
  }, []);

  
  const handleOpenModal = (transaction) => {
    setSelectedTransaction(transaction);
    // Assume bankingDetails is stored in the state
    setOpenModal(true);
  };
  

  const handleCloseModal = () => {
    setSelectedTransaction(null);  // Clear selected transaction on close
    setOpenModal(false);
  };


  const getStatusColor = (status) => {
    switch (status) {
      case 'PENDING':
        return 'warning';
      case 'COMPLETED':
        return 'success';
      case 'CANCELLED':
        return 'error';
      default:
        return 'default';
    }
  };

  const renderSkeletons = () => (
    <Paper elevation={3} sx={{ mb: 2, p: 2 }}>
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} sm={8}>
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="90%" />
        </Grid>
        <Grid item xs={12} sm={4} textAlign="right">
          <Skeleton variant="text" width="40%" height={30} />
          <Skeleton variant="text" width="50%" />
        </Grid>
      </Grid>
      <Divider light />
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="80%" />
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" width={120} height={36} />
        </Grid>
      </Box>
    </Paper>
  );

  if (loading) {
    return (
      <Box>
        <Typography variant="h6" gutterBottom>
          Transaction Overview
        </Typography>
        {renderSkeletons()}
 
      </Box>
    );
  }

  return (
    <div>
   

   <Alert
        severity="info"
        icon={<SafetyCheckIcon fontSize="inherit" />}
        sx={{ mb: 2 }}
        >
    
    <Typography variant="h6" gutterBottom>
      Transaction Overview
    </Typography>
    <Typography  variant="body1">
      <div className='fuck-this-shit'>

      This dashboard provides a detailed record of your transactions, each meticulously verified for accuracy and compliance with global financial regulations. Every transaction listed has been authenticated and is subject to continuous monitoring to uphold the highest standards of financial security and integrity. The details furnished herein include transaction identifiers, participant information, and transaction statuses, which are crucial for maintaining a transparent and secure financial environment.
      </div>
    </Typography>
    </Alert>
 
    <Divider sx={{ my: 3 }} />


      {transactions.map((transaction) => (
        <Paper key={transaction.id} elevation={3} sx={{ mb: 2, p: 2 }}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="h6" gutterBottom>
                
                Transaction #{transaction.transaction_reference}
              </Typography>
              <Tooltip title="Name of the sender" placement="top" arrow>
                <Typography variant="subtitle1" gutterBottom>
                  <PersonIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  {transaction.sender_name}
                </Typography>
              </Tooltip>
              <Tooltip title="Date and time of the transaction" placement="top" arrow>
                <Typography variant="body2" color="textSecondary">
                  <EventNoteIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  {new Date(transaction.transaction_date).toLocaleString()}
                </Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4} textAlign="right">
            <Typography variant="h6" color="success.main" gutterBottom>
          {transaction.amount >= 0 ? '+' : '-'}
          {parseFloat(transaction.amount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })}€
        </Typography>
              <Chip
                label={transaction.status}
                size="small"
                color={getStatusColor(transaction.status)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Divider light />
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Tooltip title="The unique identifier for the transaction" placement="top" arrow>
                  <Typography variant="subtitle1">
                    <ConfirmationNumberIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Reference Number: {transaction.transaction_reference}
                  </Typography>
                </Tooltip>
                <Tooltip title="Sender's International Bank Account Number" placement="top" arrow>
                  <Typography variant="subtitle1">
                    <ImportExportIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Sender IBAN: {transaction.sender_iban}
                  </Typography>
                </Tooltip>
                <Tooltip title="Sender's Account Number" placement="top" arrow>
              <Typography variant="subtitle1">
                <AccountCircleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Sender Account Number: {transaction.sender_account_number}
              </Typography>
            </Tooltip>
            <Tooltip title="Sender's Bank Branch" placement="top" arrow>
              <Typography variant="subtitle1">
                <AccountBalanceIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Sender Bank Branch: {transaction.sender_bank_branch}
              </Typography>
            </Tooltip>
            <Tooltip title="Sender's Address" placement="top" arrow>
              <Typography variant="subtitle1">
                <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Sender Address: {transaction.sender_address}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          {/* <Button variant="contained" variant="outlined" color="primary" startIcon={<HelpOutlineIcon />}>
            Transfer Info
          </Button> */}
          </Grid>
          {transaction.CAN_int && (
      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
        <Button 
          startIcon={<ReplyIcon />}
          variant="contained" 
          color="primary" 
          onClick={() => handleOpenModal(transaction)}
          sx={{ ml: 2 }}
        >
          Accept Transaction
        </Button>
      </Grid>
    )}

      </Box>
      
    </Paper>
  ))}
 {bankingDetails && bankingDetails.bank_name && (
  <TransactionModal 
  open={openModal} 
  handleClose={handleCloseModal} 
  bankName={bankingDetails.bank_name}
  transaction={selectedTransaction} // Add this
  bankingDetails={bankingDetails} // And this
/>
)}
</div>
  );
};

export default TransMainComp;
